import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Box,
} from '@mui/material';
const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  showNone: PropTypes.bool,
  color: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};
const defaultProps = {
  className: undefined,
  variant: 'standard',
  name: undefined,
  label: undefined,
  value: '',
  defaultValue: undefined,
  placeholder: 'Select',
  multiple: false,
  fullWidth: false,
  optLabel: 'label',
  optValue: 'value',
  showNone: true,
  color: 'primary',
  helperText: '',
  error: false,
};
const InputSelect = ({
  className,
  variant,
  label,
  name,
  value,
  defaultValue,
  placeholder,
  options,
  onChange,
  multiple,
  fullWidth,
  helperText,
  optLabel,
  optValue,
  showNone,
  color,
  error,
  sx,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event); // Pass the entire event object
  };
  return (
    <FormControl
      className={className}
      variant={variant}
      fullWidth={fullWidth}
      error={error}
      sx={sx}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        multiple={multiple}
        placeholder={placeholder}
        color={color}
        label={label}
        {...props}
      >
        {showNone && !multiple && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {(Array.isArray(options) ? options : []).map((option) => (
          <MenuItem
            key={option[optValue]}
            value={option[optValue]}
            disabled={option.disabled}
          >
            {option.icon ? (
              <Box display="flex" alignItems="center">
                <ListItemIcon sx={{ minWidth: '44px' }}>{option.icon}</ListItemIcon>
                {option[optLabel]}
              </Box>
            ) : (
              option[optLabel]
            )}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
InputSelect.propTypes = propTypes;
InputSelect.defaultProps = defaultProps;
export default InputSelect;