import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import Followup from "../../components/RightSidebar/Followup";
import { Container } from "@mui/material";
function MessageContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information />
            <Container maxWidth='lg'>
                <Followup />
            </Container>
        </>
    )
}
export default MessageContainer