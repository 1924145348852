import React, { useRef } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function SharePrescription() {
  const location = useLocation();
  const navigate = useNavigate();
  const printRef = useRef();

  const { title, prescription } = location.state || {};

  const handlePrint = () => {
    if (printRef.current) {
      window.print();
    }
  };

  const handleShareViaWhatsApp = () => {
    const url = window.location.href;
    const message = `Check out this prescription: ${title}\n\n${prescription}\n\n${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => alert('URL copied to clipboard!'))
      .catch((err) => console.error('Failed to copy URL:', err));
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Box ref={printRef} sx={{ padding: '20px', border: '1px solid #ccc' }}>
          <Typography variant="h4" color="#393A96" fontWeight="bold">
            {title || 'Prescription'}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            {prescription || 'No prescription details available.'}
          </Typography>
        </Box>
      </Grid>

      <Grid item container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={handleShareViaWhatsApp}>
            Share via WhatsApp
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleCopyUrl}>
            Copy URL
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SharePrescription;
