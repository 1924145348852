import * as authTypes from './authTypes';
export const login = (data, resolve, reject) => ({
  type: authTypes.LOGIN,
  data,
  resolve,
  reject,
});

export const logout = () => ({ type: authTypes.LOGOUT });

export const restoreSession = () => ({ type: authTypes.RESTORE_SESSION });

export const setAuthUser = (user) => ({ type: authTypes.SET_AUTH_USER, user });

export const setAuthUserPermissions = (permissions) => ({
  type: authTypes.SET_AUTH_USER_PERMISSIONS,
  permissions,
});
