import React from "react";

import { Container } from "@mui/material";
import SharePrescription from "../../components/RightSidebar/SharePrescription";
 function SharePrescriptionContainer(){
    return(
        <>
         <Container maxWidth='lg'>
                <SharePrescription />
            </Container>
        </>
    )
 }
  export default SharePrescriptionContainer;