import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  InputBase,
  Tooltip,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert,
  Badge,
  Stack,
  Divider,
  MenuItem,
  Typography,
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tabs,
  Tab,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import AccountMenu from '../AccountMenu';
import Sidebar from '../RightSidebar/RightSidebar';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAvailabilityRequest } from 'src/modules/app/store/appActions'; 

// Dummy data for notifications
const notifications = [
  {
    id: 1,
    avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    message: 'John Doe has scheduled an appointment for tomorrow.',
    time: '2 minutes ago',
    type: 'new',
  },
  {
    id: 2,
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
    message: 'New review received from Jane Smith.',
    time: '10 minutes ago',
    type: 'new',
  },
  {
    id: 3,
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    message: 'Reminder: Staff meeting at 3 PM.',
    time: '1 hour ago',
    type: 'old',
  },
  {
    id: 4,
    avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
    message: 'Update on clinic policies.',
    time: '3 hours ago',
    type: 'old',
  },
];

const AppointmentToggle = styled(Paper)(({ color }) => ({
  backgroundColor: color,
  width: '180px',
  border: '1px solid #eee',
  padding: 1,
  textAlign: 'center',
  '& .MuiFormControlLabel-root': {
    margin: 0,
  },
}));

const IconItem = styled(Badge)({
  '& .MuiSvgIcon-root': {
    color: '#bdbaba',
  },
  '& .MuiBadge-badge': {
    background: 'transparent',
    color: '#2157DE',
    border: '2px solid #2157DE',
    paddingTop: '2px',
  },
});

const BigTextMenuItem = styled(MenuItem)({
  fontSize: '10px',
  color: '#393A96 !important',
});

const NotificationItem = ({ notification, handleOptionsClick }) => (
  <ListItem
    alignItems="flex-start"
    secondaryAction={
      <IconButton
        edge="end"
        aria-label="options"
        onClick={(event) => handleOptionsClick(event, notification.id)}
      >
        <MoreVertIcon />
      </IconButton>
    }
  >
    <ListItemAvatar>
      <Avatar alt="Notification Avatar" src={notification.avatar} />
    </ListItemAvatar>
    <ListItemText primary={notification.message} secondary={notification.time} />
  </ListItem>
);

const NotificationList = ({ notifications, handleOptionsClick }) => (
  <Box sx={{ width: '360px', bgcolor: 'background.paper' }}>
    <Typography variant="h6" sx={{ m: 2 }}>
      Notifications
    </Typography>
    <List>
      {notifications.map((notification, index) => (
        <React.Fragment key={notification.id}>
          <NotificationItem notification={notification} handleOptionsClick={handleOptionsClick} />
          {index < notifications.length - 1 && <Divider variant="inset" component="li" />}
        </React.Fragment>
      ))}
    </List>
  </Box>
);

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app?.clinics);
  const doctorTiming = useSelector((state) => state.app?.doctorTiming); // Get doctorTiming from Redux state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAppointmentOn, setIsAppointmentOn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(notifications.length);
  const [notificationOptionsAnchorEl, setNotificationOptionsAnchorEl] = useState(null);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (clinic?.clinicId) {
      dispatch(fetchAvailabilityRequest(clinic.clinicId)); // Fetch doctor timing on clinic change
    }
  }, [clinic?.clinicId, dispatch]);

  useEffect(() => {
    // Set appointment status based on doctorTiming
    const defaultStatus = doctorTiming?.[0]?.acceptingAppointments === 'yes';
    setIsAppointmentOn(defaultStatus);
  }, [doctorTiming]);

  const handleSettingsClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/Settings`);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAppointmentToggle = (event) => {
    const isOn = event.target.checked;
    setIsAppointmentOn(isOn);
    setSnackbarOpen(true);
    if (isOn) {
      setSnackbarMessage(
        'You have successfully turned on accepting Appointments online, Patients will be able to book an appointment as per your calendar setup in the system',
      );
      setSnackbarSeverity('success');
    } else {
      setSnackbarMessage('Patient will not be able to book an appointment from outside');
      setSnackbarSeverity('error');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setUnreadCount(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionsClick = (event, notificationId) => {
    setNotificationOptionsAnchorEl(event.currentTarget);
    setSelectedNotificationId(notificationId);
  };

  const handleOptionsClose = () => {
    setNotificationOptionsAnchorEl(null);
    setSelectedNotificationId(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder=" Name, Contact Number, Token ID, Appointment ID, Case ID"
              inputProps={{
                'aria-label': 'Name, Contact Number, Token ID, Appointment ID, Case ID',
              }}
              fullWidth
            />
            <Tooltip title="Search" arrow>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={5} container alignItems="center">
          <Grid item xs={6}>
            <AppointmentToggle color={isAppointmentOn ? 'green' : 'red'}>
              <FormControlLabel
                value="appointment"
                control={
                  <Switch
                    color="primary"
                    checked={isAppointmentOn}
                    onChange={handleAppointmentToggle}
                  />
                }
                label="Appointment"
                labelPlacement="start"
              />
            </AppointmentToggle>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack spacing={2} direction="row">
              <Tooltip title="Notifications" arrow>
                <IconButton
                  color="inherit"
                  onClick={handleClick}
                  sx={{ position: 'relative', left: '10%' }}
                >
                  <Badge badgeContent={unreadCount} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 100 * 4.5,
                    width: '40ch',
                  },
                }}
              >
                <BigTextMenuItem disabled>
                  <ListItemText primary="All Notifications" />
                </BigTextMenuItem>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="All" />
                  <Tab label="Unread" />
                  <Tab label="New" />
                </Tabs>
                <NotificationList
                  notifications={notifications.filter((notification) => {
                    if (tabValue === 1) return notification.type === 'new';
                    if (tabValue === 2) return notification.type !== 'new';
                    return true;
                  })}
                  handleOptionsClick={handleOptionsClick}
                />
              </Menu>
              <Menu
                anchorEl={notificationOptionsAnchorEl}
                open={Boolean(notificationOptionsAnchorEl)}
                onClose={handleOptionsClose}
              >
                <MenuItem onClick={handleOptionsClose}>Archive</MenuItem>
                <MenuItem onClick={handleOptionsClose}>Delete</MenuItem>
                <MenuItem onClick={handleOptionsClose}>Search</MenuItem>
                <MenuItem onClick={handleOptionsClose}>Block & Spam</MenuItem>
                <MenuItem onClick={handleOptionsClose}>Details</MenuItem>
                <MenuItem onClick={handleOptionsClose}>Help & Feedback</MenuItem>
              </Menu>
              <Tooltip title="Settings" arrow>
                <IconButton
                  color="inherit"
                  onClick={handleSettingsClick}
                  sx={{ position: 'relative', left: '10px' }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" flexItem />
              <AccountMenu />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="column" sx={{ marginLeft: '100%', position: 'relative', bottom: '45px' }}>
        <IconButton
          id="header-sidebar-toggle-btn"
          onClick={toggleSidebar}
          sx={{
            backgroundColor: '#393A96',
            borderRadius: '10%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ControlPointIcon sx={{ color: 'white' }} />
        </IconButton>
      </Stack>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Header;
