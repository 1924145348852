import { type } from '@testing-library/user-event/dist/type';
import * as appTypes from './appTypes';

export const addPatientRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_PATIENT_REQUEST,
  payload: { data, resolve, reject },
});

export const addPatientSuccess = (data) => ({
  type: appTypes.ADD_PATIENT_SUCCESS,
  payload: { data },
});

export const addPatientFailure = (error) => ({
  type: appTypes.ADD_PATIENT_FAILURE,
  payload: { error },
});

// Task
export const addTaskRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TASK_REQUEST,
  payload: { data, resolve, reject },
});

export const addTaskSuccess = (data) => ({
  type: appTypes.ADD_TASK_SUCCESS,
  payload: { data },
});

export const addTaskFailure = (error) => ({
  type: appTypes.ADD_TASK_FAILURE,
  payload: { error },
});
// Staff
export const addStaffRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_STAFF_REQUEST,
  payload: { data, resolve, reject },
});

export const addStaffSuccess = (data) => ({
  type: appTypes.ADD_STAFF_SUCCESS,
  payload: { data },
});

export const addStaffFailure = (error) => ({
  type: appTypes.ADD_STAFF_FAILURE,
  payload: { error },
});

// Clinic
export const getClinics = () => ({
  type: appTypes.GET_CLINICS,
});

export const setClinics = (data) => ({
  type: appTypes.SET_CLINICS,
  data,
});

export const setCurrentClinics = (data) => ({
  type: appTypes.SET_CURRENT_CLINIC,
  data,
});
 

// Set patient
export const setCurrentPatient = (patient) => ({
  type: appTypes.SET_CURRENT_PATIENT,
  payload: patient,
});



//getPatient
export const fetchPatientsRequest = (token, resolve, reject) => ({
  type: appTypes.FETCH_PATIENTS_REQUEST,
  payload: { token, resolve, reject },
});

export const fetchPatientsSuccess = (data) => ({
  type: appTypes.FETCH_PATIENTS_SUCCESS,
  payload: { data },
});

export const fetchPatientsFailure = (error) => ({
  type: appTypes.FETCH_PATIENTS_FAILURE,
  payload: { error },

});

//fetch template
export const fetchTemplatesTitleRequest = (resolve, reject) => ({
  type: appTypes.FETCH_TEMPLATES_TITLE_REQUEST,
  payload: { resolve, reject },
});

export const fetchTemplatesTitleSuccess = (data) => ({
  type: appTypes.FETCH_TEMPLATES_TITLE_SUCCESS,
  data,
});

export const fetchTemplatesTitleFailure = (error) => ({
  type: appTypes.FETCH_TEMPLATES_TITLE_FAILURE,
  payload: { error },
});

//fetch Areaname
export const fetchAreanameRequest =(resolve,reject)=>({
  type:appTypes.FETCH_AREA_NAMES_REQUEST,
  payload: { resolve, reject },

})

export const fetchAreanameSuccess = (data) => ({
  type: appTypes.FETCH_AREA_NAMES_SUCCESS,
  data,  
});
export const fetchAreanameFailure = (error) => ({
  type: appTypes.FETCH_AREA_NAMES_FAILURE,
  payload: { error },
});



//get Saff
export const fetchStaffsRequest = (token, resolve, reject) => ({
  type: appTypes.FETCH_STAFFS_REQUEST,
  payload: { token, resolve, reject },
});

export const fetchStaffsSuccess = (data) => ({
  type: appTypes.FETCH_STAFFS_SUCCESS,
  payload: { data },
});

export const fetchStaffsFailure = (error) => ({
  type: appTypes.FETCH_STAFFS_FAILURE,
  payload: { error },
});
//get Task
export const fetchTasksRequest = (token, resolve, reject) => ({
  type: appTypes.FETCH_TASKS_REQUEST,
  payload: { token, resolve, reject },
});

export const fetchTasksSuccess = (data) => ({
  type: appTypes.FETCH_TASKS_SUCCESS,
  payload: { data },
});

export const fetchTasksFailure = (error) => ({
  type: appTypes.FETCH_TASKS_FAILURE,
  payload: { error },
});

export const setAppLoading = (loading) => ({
  type: appTypes.SET_APP_LOADING,
  loading,
});

export const fetchTokenAppointmentsRequest = ({ token, resolve, reject }) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENTS_REQUEST,
  payload: { token, resolve, reject }
});

// Action for successful fetching of token appointments
export const fetchTokenAppointmentsSuccess = (data) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENTS_SUCCESS,
  data,
});

// Action for failed fetching of token appointments
export const fetchTokenAppointmentsFailure = (error) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENTS_FAILURE,
  payload: { error },
});

//fetch  name appointmenttoken 
export const fetchTokenAppointmentnameRequest = (resolve, reject) => ({
  type: appTypes.FETCH_PATIENT_NAMES_REQUEST,
  payload: { resolve, reject },
});

export const fetchTokenAppointmentnameSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_NAMES_SUCCESS,
  data,
});

export const fetchTokenAppointmentnameFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_NAMES_FAILURE,
  payload: { error },
});


//  delete Patient
export const deletePatientRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_PATIENT_REQUEST,
  payload: { id, resolve, reject },
});

export const deletePatientSuccess = (data) => ({
  type: appTypes.DELETE_PATIENT_SUCCESS,
  payload: { data },
});

export const deletePatientFailure = (error) => ({
  type: appTypes.DELETE_PATIENT_FAILURE,
  payload: { error },
});

//delete satff

export const deleteStaffRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_STAFF_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteStaffSuccess = (data) => ({
  type: appTypes.DELETE_STAFF_SUCCESS,
  payload: { data },
});

export const deleteStaffFailure = (error) => ({
  type: appTypes.DELETE_STAFF_FAILURE,
  payload: { error },
});

//delete TokenAppointment
export const deleteTokenAppointmentRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteTokenAppointmentSuccess = (data) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_SUCCESS,
  payload: { data },
});

export const deleteTokenAppointmentFailure = (error) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_FAILURE,
  payload: { error },
});

// Action creators for fetching patient by contact number
export const fetchPatientByContactNoRequest = (contactNo, resolve, reject) => ({
  type: appTypes.FETCH_PATIENT_BY_CONTACTNO_REQUEST,
  payload: { contactNo, resolve, reject },
});

export const fetchPatientByContactNoSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_BY_CONTACTNO_SUCCESS,
  payload: data,
});

export const fetchPatientByContactNoFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_BY_CONTACTNO_FAILURE,
  payload: { error },
});


// post notes
export const postMedicalNotesRequest = (payload) => ({
  type: appTypes.POST_MEDICAL_NOTES_REQUEST,
  payload,
});

export const postMedicalNotesSuccess = (medicalRecord) => ({
  type: appTypes.POST_MEDICAL_NOTES_SUCCESS,
  payload: { medicalRecord },
});

export const postMedicalNotesFailure = (error) => ({
  type: appTypes.POST_MEDICAL_NOTES_FAILURE,
  payload: { error },
});

//post Prescription
export const postMedicalPrescriptionRequest = (payload) => ({
  type: appTypes.POST_PRESCRIPTION_REQUEST,
  payload,
});

export const postMedicalPrescriptionSuccess = (medicalRecord) => ({
  type: appTypes.POST_PRESCRIPTION_SUCCESS,
  payload: { medicalRecord },
});

export const postMedicalPrescriptionFailure = (error) => ({
  type: appTypes.POST_PRESCRIPTION_FAILURE,
  payload: { error },
});


//fetch medicalrecord
export const fetchNotesRequest = (patientId) => ({
  type: appTypes. FETCH_MEDICAL_NOTES_REQUEST,
  payload: { patientId },
});

export const fetchNotesSuccess = (data) => ({
  type: appTypes.POST_MEDICAL_NOTES_SUCCESS,
  data,
});

export const fetchNotesFailure = (error) => ({
  type: appTypes.POST_MEDICAL_NOTES_FAILURE,
  payload: { error },
});

// fetch Prescription
export const fetchPrescriptionRequest = (patientId) => ({
  type: appTypes.FETCH_PRESCRIPTIONS_REQUEST,
  payload: { patientId },
});

export const fetchPrescriptionSuccess = (data) => ({
  type: appTypes.FETCH_PRESCRIPTIONS_SUCCESS,
  data,
});

export const fetchPrescriptionFailure = (error) => ({
  type: appTypes.FETCH_PRESCRIPTIONS_FAILURE,
  payload: { error },
});

//add tag
export const addTagRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TAG_REQUEST,
  payload: { data, resolve, reject },
});

export const addTagSuccess = (data) => ({
  type: appTypes.ADD_TAG_SUCCESS,
  payload: { data },
});

export const addTagFailure = (error) => ({
  type: appTypes.ADD_TAG_FAILURE,
  payload: { error },
});
//get tag
export const fetchTagsRequest = (patientId) => ({
  type: appTypes.FETCH_TAGS_REQUEST,
  payload: { patientId },
});

export const fetchTagsSuccess = (data) => ({
  type: appTypes.FETCH_TAGS_SUCCESS,
  data,
});

export const fetchTagsFailure = (error) => ({
  type: appTypes.FETCH_TAGS_FAILURE,
  payload: { error },
});

//Add Appointment
export const postAppointmentRequest = (data, resolve, reject) => ({
  type:appTypes. POST_APPOINTMENT_REQUEST,
  payload: { data, resolve, reject },
});

export const postAppointmentSuccess = (data) => ({
  type:appTypes. POST_APPOINTMENT_SUCCESS,
  payload: { data },
});

export const postAppointmentFailure = (error) => ({
  type: appTypes.POST_APPOINTMENT_FAILURE,
  payload: { error },
});


//get Availibity
export const fetchAvailabilityRequest = (clinicId) => ({
  type: appTypes.FETCH_AVAILABILITY_REQUEST,
  payload: { clinicId },
});

export const fetchAvailabilitySuccess = (data) => ({
  type: appTypes.FETCH_AVAILABILITY_SUCCESS,
  data,
});

export const fetchAvailabilityFailure = (error) => ({
  type: appTypes.FETCH_AVAILABILITY_FAILURE,
  payload: { error },
});

//get patient Info
 
export const fetchPatientInfoRequest = (patientId) => ({
  type: 'FETCH_PATIENT_INFO_REQUEST',
  payload: { patientId },
  meta: {
    async: true,
  },
});

export const fetchPatientInfoSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_INFO_SUCCESS,
  payload: { data },
});

export const fetchPatientInfoFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_INFO_FAILURE,
  payload: { error },
});
//Add Bill
export const addBillRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_BILL_REQUEST,
  payload: { data, resolve, reject }
})

export const addBillSuccess = (data) => ({
  type: appTypes.ADD_BILL_SUCCESS,
  payload: { data }
})

export const addBillFailure = (error) => ({
  type: appTypes.ADD_BILL_FAILURE,
  payload: { error },
})

//Add Area
export const addAreaRequest = ({ data, resolve, reject }) => ({
  type: appTypes.ADD_AREA_REQUEST,
  payload: {
    data,
    resolve,
    reject,
  },
});
export const addAreaSuccess = (data) => ({
  type: appTypes.ADD_AREA_SUCCESS,
  data,
})

export const addAreaFailure = (error) => ({
  type: appTypes.ADD_AREA_FAILURE,
  payload: { error },
})
export const postTemplateRequest = (data, resolve, reject) => ({
  type: appTypes.POST_TEMPLATE_REQUEST,
  payload: { data, resolve, reject },
});

export const postTemplateSuccess = (data) => ({
  type: appTypes.POST_TEMPLATE_SUCCESS,
  payload: { data },
});

export const postTemplateFailure = (error) => ({
  type: appTypes.POST_TEMPLATE_FAILURE,
  payload: { error },
});


//post treatment
export const postTreatmentRequest = (data, resolve, reject) => ({
  type: appTypes.POST_TREATMENT_REQUEST,
  payload: { data, resolve, reject },
});

export const postTreatmentSuccess = (data) => ({
  type: appTypes.POST_TREATMENT_SUCCESS,
  payload: { data },
});

export const postTreatmentFailure = (error) => ({
  type: appTypes.POST_TREATMENT_FAILURE,
  payload: { error },
});

//area get 
export const fetchAreaRequest = (areaId) => ({
  type: appTypes.FETCH_AREA_REQUEST,
  payload: { areaId },
});

export const fetchAreaSuccess = (areaData) => ({
  type: appTypes.FETCH_AREA_SUCCESS,
  payload: { areaData },
});

export const fetchAreaFailure = (error) => ({
  type:appTypes.FETCH_AREA_FAILURE,
  payload: { error },
});
//update patient
export const updatePatientRequest = (patientId, patientData) => ({
  type:appTypes.UPDATE_PATIENT_REQUEST,
  payload: { patientId, patientData },
});

export const updatePatientSuccess = (data) => ({
  type: appTypes.UPDATE_PATIENT_SUCCESS,
  payload: data,
});

export const updatePatientFailure = (error) => ({
  type:appTypes.UPDATE_PATIENT_FAILURE,
  payload: error,
});

// get patient by id
export const fetchPatientByIdRequest = (patientId) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_REQUEST,
  payload: { patientId },
});

export const fetchPatientByIdSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_SUCCESS,
  payload: data,
});

export const fetchPatientByIdFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_FAILURE,
  payload: error,
});