import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, Grid, Tooltip, TextField, MenuItem, Button } from '@mui/material';
import { Formik } from 'formik';
import { addStaffRequest } from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import {jwtDecode} from 'jwt-decode';
import toast from 'src/utils/toast';

function Staff() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);

  const handleSubmit = async (values, { resetForm }) => {
    const token = storage.get('TOKEN');
    const decodedToken = jwtDecode(token);
    const user = decodedToken?.user;

    const staff = { ...values, user };

    console.log("Payload sent to backend:", staff);

    const promise = new Promise((resolve, reject) => {
      dispatch(addStaffRequest({ data: staff, resolve, reject }));
    });

    try {
      const response = await promise;
      toast.success(response.message || 'Staff created successfully');
      resetForm();
    } catch (error) {
      console.error('Failed to add staff:', error.message || error);
      toast.error('Failed to add staff: ' + (error.message || error));
    }
  };

  return (
    <FormBox>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          initialValues={{
            name: '',
            email: '',
            password: '',
            role: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Please enter the staff name!'),
            email: Yup.string().email('Invalid email address').required('Please enter the staff email!'),
            password: Yup.string().required('Please enter the staff password!'),
            role: Yup.string().required('Please select the staff role!'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '4%' }}>
                Add Staff
              </Typography>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Name
                  </Typography>
                  <Tooltip title="Enter the name of the staff member">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Email
                  </Typography>
                  <Tooltip title="Enter the email address of the staff member">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Password
                  </Typography>
                  <Tooltip title="Enter a password for the staff member">
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Role
                  </Typography>
                  <Tooltip title="Select the role of the staff member">
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      error={touched.role && Boolean(errors.role)}
                      helperText={touched.role && errors.role}
                    >
                      <MenuItem value="nurse">Nurse</MenuItem>
                      <MenuItem value="assistant">Assistant</MenuItem>
                    </TextField>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                  <Tooltip title="Save the Staff" arrow>
                    <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                      {isLoading ? 'Saving...' : 'Save Staff'}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Staff;
