import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Tooltip, Button, Box, Typography, TextField } from '@mui/material';
import InputAutocomplete from 'src/components/shared/Form/AutoComplete';
import { FormBox } from './style';
import { addAreaRequest, fetchAreanameRequest } from 'src/modules/app/store/appActions';
import toast from 'src/utils/toast';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'src/utils/storageUtils';
import {jwtDecode} from 'jwt-decode';

function Area() {
  const dispatch = useDispatch();
  const location = useLocation();
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  const { patient } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const [areaOptions, setAreaOptions] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        setIsLoading(true);
  
        const areas = await new Promise((resolve, reject) => {
          dispatch(fetchAreanameRequest(resolve, reject));
        });
  
        console.log('Fetched areas:', areas); // Log the fetched areas to confirm
  
        if (areas && areas.length > 0) {
          const options = areas.map((area) => ({
            label: area.area, 
            value: area.id,    
          }));
  
          console.log('Mapped area options:', options);
  
          setAreaOptions(options);
        } else {
          console.warn('No areas found');
        }
      } catch (error) {
        console.error('Error fetching areas:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchAreas();
  }, [dispatch]);
  
  

  const handleSubmit = async (values, { resetForm }) => {
    console.log('Submitting:', values); 

    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) throw new Error('User information is missing. Please log in again.');
      if (!clinic || !clinic.id) throw new Error('Clinic information is missing.');

      const areaData = {
        ...values,
        user,
        clinic: clinic.id,
        patient: patient.id, 
        status: 'ACTIVE',
      };

      await new Promise((resolve, reject) => {
        dispatch(addAreaRequest({ data: areaData, resolve, reject }));
      });

      toast.success('Area saved successfully');
      resetForm();
    } catch (error) {
      console.error('Error:', error); 
      toast.error('Failed to save area: ' + (error.message || error));
    }
  };

  return (
    <FormBox sx={{ marginTop: '20px' }}>
      <Formik
        initialValues={{ area: '' }}
        validationSchema={Yup.object().shape({
          area: Yup.string().required('Please select or type an area'),
        })}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, touched, errors }) => (
          <Form noValidate>
            <Box sx={{ marginTop: '10px', padding: '20px' }}>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h4" color="#393A96" fontWeight="bold">
                  Location
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  Area
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Tooltip title="Select or type to choose an area">
                      <Field name="area">
                        {({ field }) => (
                          <InputAutocomplete
                            {...field}
                            options={areaOptions}
                            onChange={(event, value) => {
                              setFieldValue('area', value ? value.value : '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                error={Boolean(touched.area && errors.area)}
                                helperText={touched.area && errors.area}
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Tooltip>
                  </Grid>
                  <Grid item container justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Tooltip title="Save the area">
                      <Button type="submit" variant="contained" disabled={isLoading}>
                        Save Area
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
}

export default Area;
