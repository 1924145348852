import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Stack,
  Popover,
  IconButton,
  Grid,
  Box,
  Drawer,
  Typography,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchStaffsRequest,
  deleteStaffRequest,
} from 'src/modules/app/store/appActions'; 
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';

function StaffsContainer() {
  const [open, setOpen] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
 const [selectedStaff ,setSelectedStaff] = useState(null);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchStaffsRequest(token, resolve, reject)),
      );
      if (response && response.length) {
        setStaffs(response);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleAddStaff = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/staff`);
  };

  const handleActionMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedStaffId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleEditPatient = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/patient/${selectedPatientId}`);
  };
  const handleViewDetails = (dataIndex) => () => {
    setSelectedStaff(staffs[dataIndex]);
    setDrawerOpen(true);
    handleCloseMenu();
  };

  const handleEdit = () => {
    handleCloseMenu();
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/staff`);
  };

  const handleDeleteStaff = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteStaffRequest(selectedStaffId, resolve, reject)),
    );
    if (response) {
      setStaffs(staffs.filter((staff) => staff.id !== selectedStaffId));
      handleCloseMenu();
      toast.success('Staff deleted successfully');
    } else {
      toast.error('Failed to delete staff');
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedStaff(null);
  };
  const handleNewStaffClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/staff`); 
  };
  const columns = [
    { label: 'Name', name: 'name' },
    { label: 'Email', name: 'email' },
    { label: 'Role', name: 'role' },
    {
      label: '',
      name: 'Action',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, staffs[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 190 } }}
            >
              <MenuItem onClick={handleViewDetails(dataIndex)}>
                <Stack spacing={1} direction="row">
                  <VisibilityIcon />
                  <span>View Details</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleEdit}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeleteStaff} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            </Popover>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2, position: 'relative', bottom: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={handleNewStaffClick}>
                Add New Staff
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2, position: 'relative', bottom: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={staffs}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 350, padding: 2 }}>
          {selectedStaff && (
            <>
              <Typography variant="h6" gutterBottom>
                Staff Details
              </Typography>
              <Typography>Name: {selectedStaff.name}</Typography>
              <Typography>Email: {selectedStaff.email}</Typography>
              <Typography>Role: {selectedStaff.role}</Typography>
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={closeDrawer}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default StaffsContainer;
