import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Button, Tooltip, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { addTagRequest } from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { jwtDecode } from 'jwt-decode';

function Tag() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const location = useLocation();
  const navigate = useNavigate(); 
  const { patient } = location.state || {};
  const [inputText, setInputText] = useState('');

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) {
        throw new Error('User data not available.');
      }
      if (!patient?.id) {
        throw new Error('Patient data or ID is not available.');
      }
      if (!values.tagName) {
        throw new Error('Tag name is required.');
      }
      const tagArray = values.tagName.split(',').map(tag => tag.trim());

      const tagData = {
        tag: tagArray,
        patient: patient.id,
      };

      const response = await new Promise((resolve, reject) => {
        dispatch(addTagRequest({ data: tagData, resolve, reject }));
      });

      toast.success(response.message || 'Tag created successfully');
      resetForm();

      // After adding tag, navigate back to Information component
      navigate('/information', { state: { patient } });
    } catch (error) {
      console.error('Failed to add tag:', error);
      toast.error('Failed to add tag: ' + error.message);
    }
  };

  return (
    <FormBox>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          initialValues={{ tagName: '' }}
          validationSchema={Yup.object().shape({
            tagName: Yup.string().required('Tag name is required'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                Add Tag
              </Typography>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h5" fontWeight="bold">
                  Tag Name
                </Typography>
                <Tooltip title="Enter the name of the tag">
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="tagName"
                    value={values.tagName}
                    onChange={handleChange}
                    error={touched.tagName && Boolean(errors.tagName)}
                    helperText={touched.tagName && errors.tagName}
                  />
                </Tooltip>
              </Grid>
              <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                <Tooltip title="Save the tag">
                  <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid || isLoading}>
                    {isLoading ? 'Saving...' : 'Save Tag'}
                  </Button>
                </Tooltip>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Tag;
