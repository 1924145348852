import React, { useState } from "react";
import { FormBox, RateBox } from "./style";
import { Divider, Grid, Typography, Rating, Stack } from "@mui/material";
import Textarea from "src/components/shared/Form/Textarea";
import { Button } from 'src/components/shared';
function Rateus() {
    const [value, setValue] = useState(0);

    return (
        <>
            <FormBox height="70vh">
                <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center'
                    sx={{ position: 'relative', top: '15px' }} >
                    Rate Us
                </Typography>
                <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                <Typography variant="h5" fontWeight='bold' textAlign='center'
                    sx={{ position: 'relative', top: '15px' }} >
                    Please Rate your Experience Below
                </Typography>
                <RateBox>
                    <Grid container direction="column" spacing={2} sx={{
                        padding: '30px',
                    }}>
                        <Grid item>
                            <Stack direction="Column" spacing={2} alignItems="center">
                                <Rating
                                    name="simple-controlled"
                                    value={value}
                                    size="large"
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}

                                />
                                <Grid item sx={{ position: 'relative', top: '20px' }}>
                                    <Typography variant="h5" fontWeight="bold"  >
                                        Additional Feedback
                                    </Typography>
                                    <Textarea />
                                </Grid>

                                <Grid item container  justifyContent="center" sx={{ position:"relative",top:'30px'}}>
                                    <Button variant='contained' sx={{ textAlign: 'center' }}>Submit Feedback</Button>
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </RateBox>
            </FormBox>
        </>
    );
}

export default Rateus;
