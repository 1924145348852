import React from "react";
import Grid from "@mui/material/Grid";
import {
  AuthBg,
  Left,
  Right,
  Logo,
  Icon1,
  Icon2,
  AuthContent,
  AuthContainer,
  TextBox,
} from "./styles";
import { Typography, List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  listItem: {
    position: "relative", // Ensure the bullet is positioned relative to the list item
    paddingLeft: theme.spacing(2), // Increase padding to adjust spacing between bullet and text
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: theme.spacing(1), // Increase width to increase size of bullet
      height: theme.spacing(1), // Increase height to increase size of bullet
      backgroundColor: theme.palette.secondary.main, // Customize bullet color
      borderRadius: "50%", // Ensure the bullet appears as a circle
    },
  },
}));


function AuthLayout({ children, ...props }) {
  const classes = useStyles();

  return (
    <AuthContainer maxWidth="xl">
      <AuthBg>
        <Grid container>
          <Left item xs={6}>
            <Logo />
            <Icon1 />
            <AuthContent>
              <div>{children}</div>
            </AuthContent>
          </Left>
          <Right item xs={6}>
            <TextBox>
              <Typography variant="h1" fontWeight="bold" color="white">
                Welcome To 7Health.Pro
              </Typography>
              <Typography variant="h4" color="white">
                <List>
                <ListItem className={classes.listItem}>
                    <ListItemText primary="Improve Patients Experience, Transparency & Trust" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Revenue & Reputation Optimization" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Efficiency Practice Management" />
                  </ListItem>
                 
                  <ListItem className={classes.listItem}>
                    <ListItemText primary="Clinical Process Automation" />
                  </ListItem>
                </List>
              </Typography>
            </TextBox>
          </Right>
        </Grid>
      </AuthBg>
    </AuthContainer>
  );
}

export default AuthLayout;
