import React from "react";
import Contactus from "../../components/SettingPage/Contactus";
import { Container } from "@mui/material";
 function ContactusContainer(){
    return(
        <>
            <Container maxWidth="lg">
        <Contactus />
      </Container> 
        </>
    )
 }
  export default ContactusContainer;