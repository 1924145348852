import { Container } from "@mui/material";
import React from "react";
import Welcome from "../../components/patient/Welcome";
import Patient from "../../components/RightSidebar/Patient";
function PatientContainer() {
    return (
         <>    
          {/* <Welcome /> */}
            <Container maxWidth='lg'>
                <Patient />
            </Container>
        </>
    )
}

export default PatientContainer