import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import PatientToken from "../../components/RightSidebar/PatientToken";
import { Container,Box,Grid } from "@mui/material";

 function PatientTokenContainer(){
    return(
        <>
            {/* <Welcome/> */}
           
            <Container maxWidth='lg'>
            <Information/>
                <PatientToken/>
                
            </Container>
           
        </>
    )
 }
 export default PatientTokenContainer