import { method } from "lodash";

export const payment= [
    {

        index:1,
        name:'user1 ',
        method:'VISA',
        date:'23-3-2021',
        amount:'$99.2',
        invoice:''
        
    },
    {

        index:2,
        name:'user1 ',
        method:'UPI',
        date:'23-3-2021',
        amount:'$99.2',
        invoice:''
        
    },
    {

        index:3,
        name:'user1 ',
        method:'VISA',
        date:'23-3-2021',
        amount:'$99.2',
        invoice:''
        
    },
    {

        index:4,
        name:'user1 ',
        method:'UPI',
        date:'23-3-2021',
        amount:'$99.2',
        invoice:''
        
    },
    {

        index:5,
        name:'user1 ',
        method:'VISA',
        date:'23-3-2021',
        amount:'$99.2',
        invoice:''
        
    },
  
]