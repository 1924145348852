import React, { useEffect } from 'react';
import { Grid, Typography, Tooltip, Button, Box, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addBillRequest } from 'src/modules/app/store/appActions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NumberInput from 'src/components/shared/Form/Number';
import Select from 'src/components/shared/Form/Select';
import { FormBox } from './style';
import toast from 'src/utils/toast';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';


function Bill() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  const paymentTypes = ['X-ray', 'Medicine', 'Consultation'];
  const paymentMethods = ['Cash', 'Credit Card', 'Debit Card', 'UPI'];
  const billTypes = ['debit', 'credit'];
  const location = useLocation();
  const { patient } = location.state || {};
  useEffect(() => {
    // Any initialization logic can go here if needed
  }, [dispatch]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

    if (!clinic || !clinic.id) throw new Error('Clinic information is missing.');  
      if (!user) throw new Error('User information is missing. Please log in again.');
    

      const billData = {
        ...values,
        user: user.id,
        clinic: clinic.id,
        patient: patient.id,
        tokenAppointment: patient.tokenappointment,
        // Ensure you have all necessary fields in the billData object
      };

      const response = await new Promise((resolve, reject) => {
        dispatch(addBillRequest({ data: billData, resolve, reject }));
      });

      toast.success(response.message || 'Bill created successfully');
      resetForm();
    } catch (error) {
      toast.error('Failed to add bill: ' + (error.message || error));
    }
  };

  return (
    <FormBox sx={{ marginTop: '20px' }}>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
          Bill
        </Typography>
        <Formik
          initialValues={{
            paymentFor: '',
            amount: '',
            date: null,
            type: '',
            method: '',
          }}
          validationSchema={Yup.object().shape({
            paymentFor: Yup.string().required('Please select the type of payment'),
            amount: Yup.number().required('Please enter the amount'),
            date: Yup.date().required('Please select the date'),
            type: Yup.string().required('Please select the type of payment'),
            method: Yup.string().required('Please select the payment method'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, touched, errors }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Box sx={{ marginTop: '10px', padding: '20px' }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Payment For
                    </Typography>
                    <Tooltip title="Select the type of payment (e.g., X-ray, Medicine, etc.)">
                      <Select
                        variant="outlined"
                        fullWidth
                        name="paymentFor"
                        options={paymentTypes.map((type) => ({ label: type, value: type }))}
                        value={values.paymentFor}
                        onChange={handleChange}
                      />
                    </Tooltip>
                    {touched.paymentFor && errors.paymentFor && (
                      <Typography color="error" variant="body2">
                        {errors.paymentFor}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Amount
                    </Typography>
                    <Tooltip title="Enter the payment amount">
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                      />
                    </Tooltip>
                    {touched.amount && errors.amount && (
                      <Typography color="error" variant="body2">
                        {errors.amount}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Pick Date
                    </Typography>
                    <Tooltip title="Select the date of the payment">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          value={values.date ? dayjs(values.date) : null}
                          onChange={(newDate) => setFieldValue('date', newDate?.toISOString())}
                          renderInput={(params) => <NumberInput {...params} />}
                        />
                      </LocalizationProvider>
                    </Tooltip>
                    {touched.date && errors.date && (
                      <Typography color="error" variant="body2">
                        {errors.date}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Type
                    </Typography>
                    <Tooltip title="Select the type of payment (e.g., debit, credit)">
                      <Select
                        variant="outlined"
                        fullWidth
                        name="type"
                        options={billTypes.map((type) => ({ label: type, value: type }))}
                        value={values.type}
                        onChange={handleChange}
                      />
                    </Tooltip>
                    {touched.type && errors.type && (
                      <Typography color="error" variant="body2">
                        {errors.type}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Payment Method
                    </Typography>
                    <Tooltip title="Select the payment method (e.g., Cash, Credit Card, Debit Card, UPI)">
                      <Select
                        variant="outlined"
                        fullWidth
                        name="method"
                        options={paymentMethods.map((method) => ({ label: method, value: method }))}
                        value={values.method}
                        onChange={handleChange}
                      />
                    </Tooltip>
                    {touched.method && errors.method && (
                      <Typography color="error" variant="body2">
                        {errors.method}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item container justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Tooltip title="Save the payment">
                      <Button type="submit" variant="contained" disabled={isLoading}>
                        Save Bill
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Bill;
