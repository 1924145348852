import * as authTypes from './authTypes';

const initialState = {
  token: '',
  isAuthenticated: false,
  user: {},
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case authTypes.SET_AUTH_USER:
      return { ...state, isAuthenticated: true, user: payload.user };

    case authTypes.SET_AUTH_USER_PERMISSIONS:
      return { ...state, isAuthenticated: true, permissions: payload.permissions };

    default:
      return state;
  }
};
