import React from "react";
import { Container } from "@mui/material";
import Language from "../../components/SettingPage/Language";
function LanguageContainer() {
  return (
    <>
      <Container maxWidth="lg">
        <Language/>
      </Container>
    </>
  )
}
export default LanguageContainer;