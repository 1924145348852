import React from "react";
import { ContactBox, DesignBox, FormBox, InfoBox, } from "./style";
import { Divider, Grid, Typography, Stack, Avatar, } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Textarea from "src/components/shared/Form/Textarea";
import { Button } from 'src/components/shared';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import facebook from "src/assets/images/facebook .png";
import instagram from "src/assets/images/instagram .png";
import twitter from "src/assets/images/twitter.png";
import linkedin from "src/assets/images/linkedin .png"
function Contactus() {
    return (
        <>
            <FormBox height="110vh">
                <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center'
                    sx={{ position: 'relative', top: '15px' }} >
                    Contact Us
                </Typography>
                <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                <Typography variant="h4" fontWeight='bold'
                    sx={{ position: 'relative', top: '15px', left: "4%" }} >
                    Get in Touch
                </Typography>
                <Typography variant="h3" color='#393A96' fontWeight='bold'
                    sx={{ position: 'relative', top: '15px', left: "4%" }} >
                    Let's Chat , Reach Out to Us
                </Typography>

                <InfoBox >
                    <Grid container direction="column" spacing={2} sx={{
                        padding: '30px',
                    }}>
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DesignBox

                                >
                                    <LocationOnOutlinedIcon sx={{ color: '#393A96' }} />
                                </DesignBox>
                                <Typography variant="h5" fontWeight="bold" color='white'>
                                    Akik Tower S.G Highway,Bopal
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DesignBox

                                >
                                    <EmailOutlinedIcon sx={{ color: '#393A96' }} />
                                </DesignBox>
                                <Typography variant="h5" fontWeight="bold" color='white'>
                                    sevencore7890@gmail.com
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DesignBox

                                >
                                    <PhoneOutlinedIcon sx={{ color: '#393A96' }} />
                                </DesignBox>
                                <Typography variant="h5" fontWeight="bold" color='white'>
                                    +91 9876543210
                                </Typography>
                            </Stack>
                        </Grid>

                        <ContactBox>
                            <Grid container direction="column" spacing={2} sx={{
                                padding: '10px',
                            }}>
                                <Grid item>
                                    <Stack direction="row" spacing={5} alignItems="center">

                                        <Avatar src={facebook} />
                                        <Avatar src={instagram} />
                                        <Avatar src={twitter} />
                                        <Avatar src={linkedin} />



                                    </Stack>
                                </Grid>
                            </Grid>
                        </ContactBox>

                    </Grid>

                </InfoBox>

                <Grid container direction="column" spacing={2} sx={{
                    padding: '30px', position: 'relative',
                    bottom: '300px'
                }}>
                    <Grid item>
                        <Stack direction="column" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                First Name
                            </Typography>

                        </Stack>
                        <Stack direction="column" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />

                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="column" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Last Name
                            </Typography>

                        </Stack>
                        <Stack direction="column" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />

                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="column" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Email Address
                            </Typography>

                        </Stack>


                        <Stack direction="column" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />

                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="column" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Contact Number
                            </Typography>

                        </Stack>
                        <Stack direction="column" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />

                        </Stack>
                    </Grid>
                    <Grid item sx={{ position: 'relative', bottom: "20px" }}>
                        <Typography variant="h5" fontWeight='bold'>
                            Message
                        </Typography>

                        <Textarea variant='outlined' />
                    </Grid>
                    <Grid item container spacing={2} justifyContent="center">
                        <Button variant='contained' sx={{ textAlign: 'center' }}>Send Message</Button>
                    </Grid>
                </Grid>






            </FormBox>
        </>
    )
}
export default Contactus;