import React from "react";
import Rateus from "../../components/SettingPage/Rateus";
import { Container } from "@mui/material";
function RateusContainer() {
  return (
    <>
      <Container maxWidth="lg">
        <Rateus />
      </Container>
    </>
  )
}
export default RateusContainer;