import React, { useState } from 'react';
import { FormBox } from './style';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Button } from 'src/components/shared';
import { styled } from '@mui/system';

const StyledInputRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledInputElement = styled('input')({
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '8px',
  fontSize: '16px',
  flex: 1,
});

const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  const { value, onChange } = props;

  const handleIncrement = () => {
    onChange({ target: { value: Number(value) + 1 } });
  };

  const handleDecrement = () => {
    onChange({ target: { value: Number(value) - 1 } });
  };

  return (
    <StyledInputRoot sx={{ width: '100%', textAlign: 'center' }}>
      <StyledInputElement
        type="number"
        value={value}
        onChange={onChange}
        ref={ref}
      />
    </StyledInputRoot>
  );
});

function Followup() {
  const [number, setNumber] = useState(0);

  const handleNumberChange = (event) => {
    setNumber(Number(event.target.value));
  };

  const handleSave = () => {
    // Implement your save logic here
    console.log("Saving follow-up count:", number);
  };

  return (
    <>
      <FormBox sx={{ marginTop: '20px'}}>

<Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
<Typography
    variant="h4"
    color='#393A96'
    fontWeight='bold'
    sx={{  marginLeft: '2%' }}
>
   Follow-Up
</Typography>
          <Grid item>
            <Typography variant='h5' fontWeight='bold'>How many follow-ups?</Typography>
            <Tooltip title="Enter the number of follow-ups" arrow>
              <CustomNumberInput
                value={number}
                onChange={handleNumberChange}
              />
            </Tooltip>
          </Grid>

          <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
            <Tooltip title="Save the number of follow-ups" arrow>
              <Button variant='contained' onClick={handleSave} sx={{ width: '200px', textAlign: 'center' }}>
                Save Followup
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </FormBox>
    </>
  );
}

export default Followup;
