import api from 'src/api';
import Patient from '../components/RightSidebar/Patient';
export const fetchPatients = () => {
  return api('/patients',null, 'get');
};

export const addPatient = (data) => {
  return api('/patient', data, 'post');
};

export const fetchTemplate = () =>{
  return api(`/templates`, null, 'get');
}

export const deletePatient = (id) => {
  return api(`/patient/${id}`, null, 'delete');
};


export const deleteStaff = (id) => {
  return api(`/staff/${id}`, null, 'delete');
};

export const deleteTokenAppointment = (id) => {
  if (!id) {
    throw new Error('tokenApointment ID is required to delete a tokenappointment.');
  }
  return api(`/tokenappointment/${id}`, null, 'delete');
};

export const addTask = (data) => {
  return api('/task', data, 'post');
};

export const getclinics = () => {
  return api(`/clinic`, null, 'get');
};
export const addStaff = (data) => {
  return api('/staff', data, 'post');
};
export const fetchStaffs = () => {
  return api('/staff',null, 'get');
};
export const fetchTasks = () => {
  return api('/tasks',null, 'get');
};

export const fetchTokenAppointments = () => {
  return api('/tokenappointments', null, 'get');
};
export const fetchPatientByContactNo = (contactNo) => {
  return api('/patientcontact', { params: { contactNo } }, 'get');
};

export const addMedicalRecord = (data) => {
  return api('/medicalrecord', data, 'post');
};
export const fetchMedicalRecords = (patientId) => {
  return api(`/medicalrecord/${patientId}`, 'get'); 
};

 export const addTag = (data) => {
  return api('/tag', data, 'post');
};

export const fetchTags = (patientId) => {
  return api(`/tag/${patientId}`, 'get'); 
};
export const addAppointment = (data) => {
  return api('/tokenappointment', data, 'post');
};

export const fetchAvailability = (clinicId) => {
  return api(`/getavailability/${clinicId}`, null, 'get');
};

export const fetchPatientInfo = (patientId) => {
  if (!patientId) {
    throw new Error('Patient Info ID is required');
  }
  return api(`/patientinfo/${patientId}`, 'get');
};

export const addBill = (data) => {
  return api('/billing', data, 'post');
};

export const addArea =(data)=>{
  return api('/area',data,'post');
}

export const AddTemplate=(data)=>{
  return api('/template',data,'post' )
}

export const getMessageTemplate = () =>{
  return api(`/messages`, null, 'get');
}

export const fetchAreas = () =>{
  return api(`/areas`,null,'get')
}

export const addTreatment = (data)=>{
  return api(`/treatment`,data,'post')
}


export const fetchArea =(areaId)=>{
  return api(`/area/${areaId}`,null,'get')
}

export const updatePatientById = (patientId, data) => {
  return api(`/patient/${patientId}`, data, 'put');
};

export const getPatientById = (patientId) => {
  return api(`/patient/${patientId}`, 'get');
};