import React, { useState, useEffect } from 'react';
import { FormBox } from './style';
import { Grid, Typography, Button, Tooltip } from '@mui/material';
import Textarea from 'src/components/shared/Form/Textarea';
import Select from 'src/components/shared/Form/Select'; // Adjust this path as per your actual file structure

function Message() {
  const [isInfoPresent, setIsInfoPresent] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const checkInfo = () => {
    setIsInfoPresent(false); // Placeholder logic to check if info is present
  };

  useEffect(() => {
    checkInfo();
  }, []);

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  return (
    <FormBox sx={{ marginTop: '20px' }}>

      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography
          variant="h4"
          color='#393A96'
          fontWeight='bold'
          sx={{ marginLeft: '2%' }}
        >
          Message Template
        </Typography>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Language
          </Typography>
          <Tooltip title="Select the language">
            <Select variant="outlined" fullWidth />
          </Tooltip>
        </Grid>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Template
          </Typography>
          <Tooltip title="Select a message template">
            <Select 
              variant="outlined" 
              fullWidth 
              value={selectedTemplate} 
              onChange={handleTemplateChange}
            >
              <option value="Welcome Template">Welcome Template</option>
              {/* Add more options as needed */}
            </Select>
          </Tooltip>
        </Grid>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Message
          </Typography>

          <Tooltip title="Enter your message">
            <Textarea variant="outlined" fullWidth rows={2} minRows={2} maxRows={2} />
          </Tooltip>

          {!isInfoPresent && (
            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={4}>
                <Tooltip title="Insert First Name">
                  <Button variant="outlined" fullWidth>
                    First Name
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Tooltip title="Insert Last Name">
                  <Button variant="outlined" fullWidth>
                    Last Name
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Tooltip title="Insert Mobile Number">
                  <Button variant="outlined" fullWidth>
                    Mobile No
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent="center" sx={{ marginTop: '40px' }}>
            <Tooltip title="Save your message">
              <Button variant="contained" sx={{ textAlign: 'center' }}>
                Save Message
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </FormBox>
  );
}

export default Message;
