import { template } from 'lodash';
import * as appTypes from './appTypes';
import { recomposeColor } from '@mui/material';

const initialState = {
  isLoading: false,
  patient: [],
  patients: [],
  tokenAppointments: [],
  tokenAppointment: [],
  task: [],
  tasks: [],
  area: [],
  areas: [],
  patientNames: [],
  medicalRecord: [],
  medicalRecords: [],
  templates: [],
  treatment: [],
  getavailability: [],
  staff: [],
  staffs: [],
  tag: [],
  tags: [],
  patientInfo: null,
  error: null,
  success: null,
  message: null,
  bill: null,
  template: null,
  // area: null,
  patientInfo: {},
  patients: {
    data: [],
    currentPatient: null,
    patientId: null,
    patientName: null,
  },
  clinics: {
    data: [],
    currentClinic: {},
    clinicId: 0,
    doctorId: 0,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    // Patient
    case appTypes.ADD_PATIENT_SUCCESS:
      return { ...state, isLoading: false, patient: [...state.patient, payload.data] };

    case appTypes.ADD_PATIENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    // Task
    case appTypes.ADD_TASK_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_TASK_SUCCESS:
      return { ...state, isLoading: false, task: [...state.task, payload.data] };
    case appTypes.ADD_TASK_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    //get Tasks
    // case appTypes.FETCH_TASKS_REQUEST:
    // return { ...state, isLoading: true };
    case appTypes.FETCH_TASKS_SUCCESS:
      return { ...state, isLoading: false, tasks: payload.data };
    case appTypes.FETCH_TASKS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get patient Info

    // case appTypes.FETCH_PATIENT_INFO_SUCCESS:
    //   console.log('Reducer data:', payload.data);
    //   return { ...state, isLoading: false, patientInfo: payload.data };
    // case appTypes.FETCH_PATIENT_INFO_FAILURE:
    //   return { ...state, isLoading: false, error: payload.error, patientInfo: null  };

    //getPatients
    // case appTypes.FETCH_PATIENT_NAMES_REQUEST:
    //   return { ...state, isLoading: true };
    case appTypes.FETCH_PATIENTS_SUCCESS:
      return { ...state, isLoading: false, patients: payload.data };
    case appTypes.FETCH_PATIENTS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get Appointment
    // case appTypes.FETCH_TOKENAPPOINTMENT_REQUEST:
    //   return { ...state, isLoading: true };
    case appTypes.FETCH_TOKENAPPOINTMENTS_SUCCESS:
      return { ...state, isLoading: false, tokenAppointments: payload.data };

    case appTypes.FETCH_TOKENAPPOINTMENTS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    //get name tokenAppointment
    // case appTypes.FETCH_PATIENT_NAMES_REQUEST:
    // return { ...state, isLoading: true };

    case appTypes.FETCH_PATIENT_NAMES_SUCCESS:
      // console.log('Reducer data:', payload.data); // Log the data
      return { ...state, isLoading: false, tokenAppointments: payload.data };

    case appTypes.FETCH_PATIENT_NAMES_FAILURE:
      // console.log('Reducer error:', payload.error); // Log the error
      return { ...state, isLoading: false, error: payload.error };

    //fetch patient Info
    // case appTypes.FETCH_PATIENT_INFO_REQUEST:
    //   return {...state ,isLoading:true};

    // Staff
    case appTypes.ADD_STAFF_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_STAFF_SUCCESS:
      return { ...state, isLoading: false, staff: [...state.staff, payload.data] };

    case appTypes.ADD_STAFF_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    // Loading
    case appTypes.SET_APP_LOADING:
      return { ...state, isLoading: payload.loading };

    //getStaff
    // case appTypes.FETCH_STAFFS_REQUEST:
    //   return { ...state, isLoading: true };
    case appTypes.FETCH_STAFFS_SUCCESS:
      return { ...state, isLoading: false, staffs: payload.data };
    case appTypes.FETCH_STAFFS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    // Case for fetching patient by contact number
    // case appTypes.FETCH_PATIENT_BY_CONTACTNO_REQUEST:
    //   return { ...state, isLoading: true };
    case appTypes.FETCH_PATIENT_BY_CONTACTNO_SUCCESS:
      const { fullName, age, gender } = payload.data;
      return {
        ...state,
        isLoading: false,
        patient: { fullName, age, gender },
        error: null,
      };

    case appTypes.FETCH_PATIENT_BY_CONTACTNO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        patient: { fullName: '', age: '', gender: '' },
      };
    //post notes
    case appTypes.POST_MEDICAL_NOTES_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.POST_MEDICAL_NOTES_SUCCESS:
      return { ...state, isLoading: false, medicalRecords: [...state.medicalRecord, payload.data] };

    case appTypes.POST_MEDICAL_NOTES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //post Prescription

    case appTypes.POST_PRESCRIPTION_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.POST_PRESCRIPTION_SUCCESS:
      return { ...state, isLoading: false, medicalRecords: [...state.medicalRecord, payload.data] };
    case appTypes.POST_PRESCRIPTION_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //post Template
    case appTypes.POST_TEMPLATE_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.POST_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false, templates: [...state.templates, payload.data] };
    case appTypes.POST_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch notes
    // case appTypes.FETCH_MEDICAL_RECORDS_REQUEST:
    // return { ...state, isLoading: true };

    //post bill
    case appTypes.ADD_BILL_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_BILL_SUCCESS:
      return { ...state, isLoading: false, bill: payload.data };
    case appTypes.ADD_BILL_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add Area
    case appTypes.ADD_AREA_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_AREA_SUCCESS:
      return { ...state, isLoading: false, area: payload.data };

    case appTypes.ADD_AREA_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    // post treatment
    case appTypes.POST_TREATMENT_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.POST_TREATMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        treatment: [...state.treatment, payload.data],
      };
    case appTypes.POST_TREATMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //fetch area
    // case FETCH_AREA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    case appTypes.FETCH_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        area: payload.areaData,
      };
    case appTypes.FETCH_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    // add Appointment
    case appTypes.POST_APPOINTMENT_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.POST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tokenAppointment: [...state.tokenAppointment, payload.data],
      };
    case appTypes.POST_APPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    // get medicalrecord NOTES
    case appTypes.FETCH_MEDICAL_NOTES_SUCCESS:
      return { ...state, isLoading: false, medicalRecords: payload.data };
    case appTypes.FETCH_MEDICAL_NOTES_FAILURE:
      return { ...state, isLoading: false, error: payload.error, medicalRecords: [] };

    //fetch PrescriptioN
    // case appTypes.FETCH_PRESCRIPTIONS_REQUEST:
    //   return { ...state, isLoading: true };
    case appTypes.FETCH_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicalRecords: payload.data,
      };
    case appTypes.FETCH_PRESCRIPTIONS_FAILURE:
      return { ...state, isLoading: false, error: payload.error, medicalRecords: [] };

    //get Availibity
    // case appTypes.FETCH_AVAILABILITY_FAILURE:
    //   return {...state, isLoading: true};
    case appTypes.FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getavailability: [...state.getavailability, payload.data],
      };
    case appTypes.FETCH_AVAILABILITY_FAILURE:
      return { ...state, isLoading: false, error: payload.error, getavailability: [] };
    //get Template
    case appTypes.FETCH_TEMPLATES_TITLE_SUCCESS:
      return { ...state, isLoading: false, templates: payload.data };
    case appTypes.FETCH_TEMPLATES_TITLE_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get Area
    case appTypes.FETCH_AREA_NAMES_SUCCESS:
      console.log('Areas fetched from API:', payload.data);
      return { ...state, isLoading: false, areas: payload.data };
    case appTypes.FETCH_AREA_NAMES_FAILURE:
      console.error('Failed to fetch areas:', payload.error);
      return { ...state, isLoading: false, error: payload.error };

    //add tag
    case appTypes.ADD_TAG_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_TAG_SUCCESS:
      return { ...state, isLoading: false, tag: [...state.tag, payload.data] };
    case appTypes.ADD_TAG_FAILURE:
      return { ...state, isLoading: false, error: payload.error };
    //get tag
    // case appTypes.FETCH_TAGS_REQUEST:
    //   return { ...state, isLoading: true };

    case appTypes.FETCH_TAGS_SUCCESS:
      return { ...state, isLoading: false, tags: payload.data };

    case appTypes.FETCH_TAGS_FAILURE:
      return { ...state, isLoading: false, error: payload.error, tags: [] };

    //get patient info

    //  case appTypes.FETCH_PATIENT_INFO_REQUEST:
    //   return { ...state, isLoading: true, error: null };
    case appTypes.FETCH_PATIENT_INFO_SUCCESS:
  return { ...state, isLoading: false, patientInfo: payload.data };
case appTypes.FETCH_PATIENT_INFO_FAILURE:
  return { ...state, isLoading: false, error: payload.error };

    //delete patient

    case appTypes.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: state.patients
          ? state.patients.filter((patient) => patient.id !== payload.id)
          : [],
      };

    case appTypes.DELETE_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //delete Staff
    case appTypes.DELETE_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffs: state.staffs ? state.staffs.filter((staff) => staff.id !== payload.id) : [],
      };
    case appTypes.DELETE_STAFF_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //delete tokenAppointment
    case appTypes.DELETE_TOKENAPPOINTMENT_SUCCESS:
      return {
        ...state,
        tokenAppointments: state.tokenAppointments
          ? state.tokenAppointments.filter((tokenAppointment) => tokenAppointment.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_TOKENAPPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    // Clinic
    case appTypes.SET_CLINICS:
      return { ...state, clinics: { ...state.clinics, data: payload.data?.clinics } };

    case appTypes.SET_CURRENT_CLINIC:
      const { clinicId, doctorId } = payload?.data || {};
      const currentClinic = state.clinics.data?.find((clinic) => clinic.id === clinicId) ?? {};
      return { ...state, clinics: { ...state.clinics, currentClinic, clinicId, doctorId } };

    //current selected Patient
    // case appTypes.SET_PATIENTS:
    //   return {...state, patients: { ...state.patients ,data:payload.data?.patients}};

    case appTypes.SET_CURRENT_PATIENT:
      return {
        ...state,
        currentPatient: payload.data,
      };
    //update patient
    case appTypes.UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case appTypes.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: payload.data,
      };
    case appTypes.UPDATE_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    // case appTypes.FETCH_PATIENT_BY_ID_REQUEST:
    //   return{
    //     ...state,loading:true,
    //     error:null,};
      case appTypes.FETCH_PATIENT_BY_ID_SUCCESS:
        return{
          ...state,
          loading :false,
          error:payload.data
        }
      case appTypes.FETCH_PATIENT_BY_ID_FAILURE:
        return{
          ...state,
          loading :false,
          error: payload.error,
        }

        default:
      return state;
  }
};
