import React, { useState } from "react";
import { FormBox } from "./style";
import { Divider, Grid, Typography, Stack, Snackbar, Alert, Switch, Button } from "@mui/material";

function Notifications() {
   

    const [snackbarOpen, setSnackbarOpen] = useState(false);

  

    const handleSaveSettings = () => {
        setSnackbarOpen(true);
    };

    return (
        <>
            <FormBox height='70vh'>
                <Typography variant="h4" color="#393A96" fontWeight="bold" textAlign="center" sx={{ position: 'relative', top: '15px' }} >
                    Notifications
                </Typography>
                <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                <Grid container direction="column" spacing={2} marginTop='10px' marginLeft='15px'>
                <Grid item>
                    <Stack direction="row" alignItems="center"   justifyContent="space-between" sx={{ width: '90%' }}>
                        <Typography variant="h5" fontWeight='bold'>Push Notification</Typography>
                        <Switch name="pushNotification"  />
                    </Stack>
                    <Divider orientation="horizontal" sx={{ marginTop: '10px',width:'90%' }} />
                </Grid>
                <Grid item>
                    <Stack direction="row" alignItems="center"   justifyContent="space-between" sx={{ width: '90%' }}>
                        <Typography variant="h5" fontWeight='bold'>Web Notification</Typography>
                        <Switch name="pushNotification"  />
                    </Stack>
                    <Divider orientation="horizontal" sx={{ marginTop: '10px',width:'90%' }} />
                </Grid>
                <Grid item>
                    <Stack direction="row" alignItems="center"   justifyContent="space-between" sx={{ width: '90%' }}>
                        <Typography variant="h5" fontWeight='bold'>WhatsApp Notification</Typography>
                        <Switch name="pushNotification"  />
                    </Stack>
                    <Divider orientation="horizontal" sx={{ marginTop: '10px',width:'90%' }} />
                </Grid>

                <Grid item>
                    <Stack direction="row" alignItems="center"   justifyContent="space-between" sx={{ width: '90%' }}>
                        <Typography variant="h5" fontWeight='bold'>Email Notifications </Typography>
                        <Switch name="pushNotification"  />
                    </Stack>
                    <Divider orientation="horizontal" sx={{ marginTop: '10px',width:'90%' }} />
                </Grid>
                <Grid item>
                    <Stack direction="row" alignItems="center"   justifyContent="space-between" sx={{ width: '90%' }}>
                        <Typography variant="h5" fontWeight='bold'>SMS Notifications </Typography>
                        <Switch name="pushNotification" disabled />
                    </Stack>
                    {/* <Divider orientation="horizontal" sx={{ marginTop: '10px',width:'90%' }} /> */}
                </Grid>
                
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                        Notification settings saved successfully!
                    </Alert>
                </Snackbar>
            </FormBox>  
        </>
    );
}

export default Notifications;
