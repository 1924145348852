import React from 'react';
import { FormBox } from './style';
import { Grid, Typography, Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputAutocomplete from 'src/components/shared/Form/AutoComplete';
// import tokenImage from 'src/assets/token.png'; // Import the image file
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
function PatientToken() {
  const clinic = useSelector((state) => state.app.clinics);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/Patient`);
  };

  return (
    <FormBox height="45vh">
      <Grid container justifyContent="center" sx={{ marginBottom: '10px' }}>
        {/* <img src={tokenImage} alt="Token" style={{ width: '100px', height: '100px' }} /> */}
      </Grid>
      <Typography
        variant="h4"
        color="#393A96"
        fontWeight="bold"
        sx={{ position: 'relative', top: 20, left: '4%' }}
      >
        Token
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'flex-end', position: 'relative', right: '20px' }}
      >
        <Grid item>
          <Tooltip title="Add a new patient">
            <Button variant="contained" sx={{ textAlign: 'center' }} onClick={handleButtonClick}>
              Add New Patient
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Symptoms
          </Typography>
          <Tooltip title="Select patient symptoms">
            <InputAutocomplete
              variant="outlined"
              sx={{ position: 'relative', bottom: '10px' }}
              fullWidth
              options={[
                { label: 'Fever', value: 'Fever' },
                { label: 'Neck Pain', value: 'Neck Pain' },
              ]}
            />
          </Tooltip>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Tooltip title="Book the patient token">
              <Button variant="contained" sx={{ textAlign: 'center' }}>
                Save Token
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </FormBox>
  );
}

export default PatientToken;
