import React from "react";
import Welcome from "../../components/patient/Welcome"
import { Container } from "@mui/material";
import Setting from "../../components/DashBoard/Setting";
function SettingContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Container maxWidth='lg'>
                <Setting />
            </Container>
        </>
    )
}
export default SettingContainer