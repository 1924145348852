import axios from 'axios';
import storage, { storageKey } from 'src/utils/storageUtils';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/`,
  headers: {},
});

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  const errData = error?.response?.data || {};
  switch (error?.response?.status) {
    case 403:
      storage.del('TOKEN');
      break;
    case 404:
      break;
    default:
      break;
  }
  return Promise.reject(errData);
};

const handleAuth = (config, isFormData) => {
  const token = storage.get('TOKEN');
  const tenantHash = storage.get('TENANT_HASH');
  if (token && token !== '' && token !== null && typeof token !== 'undefined') {
    config.headers = {
      'Content-Type': isFormData
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + token,
    };
    config.credentials = 'include';
  }
  return config;
};

export default function api(path, payload, method, isFormData, onUploadProgress) {
  client.interceptors.response.use(handleSuccess, handleError);
  client.interceptors.request.use(async (config) => {
    return handleAuth(config, isFormData);
  });

  return new Promise((resolve, reject) => {
    client
      .request({
        method: method,
        url: path,
        responseType: 'json',
        data: payload || {},
        ...(onUploadProgress && { onUploadProgress }),
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        const errData = error?.response?.data || {};
        return reject(errData);
      });
  });
}
