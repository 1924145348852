import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBox } from './style';
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { postTreatmentRequest } from '../../store/appActions';
import {jwtDecode} from 'jwt-decode';
import storage from 'src/utils/storageUtils';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

function Treatment() {
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  const location = useLocation();
  const { patient, doctorDefaultTime } = location.state || {}; // Retrieve doctor's default time from location state

  const [sittings, setSittings] = useState(0);
  const [dateTimes, setDateTimes] = useState([]);
  const [treatmentType, setTreatmentType] = useState('visiting');
  const [treatmentName, setTreatmentName] = useState('');
  const [total, setTotal] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [time, setTime] = useState(() => {
    return doctorDefaultTime
      ? dayjs(doctorDefaultTime, 'HH:mm').format('HH:mm')
      : dayjs().format('HH:mm');
  }); 
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  useEffect(() => {
    if (doctorDefaultTime) {
      setTime(dayjs(doctorDefaultTime, 'HH:mm').format('HH:mm'));
    }
  }, [doctorDefaultTime]);

  const handleSittingsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSittings(value);
    setDateTimes(Array(value).fill({ date: null, time: null }));
  };

  const handleDateChange = (index, date) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index] = { ...newDateTimes[index], date };
    setDateTimes(newDateTimes);
  };

  const handleTimeChange = (index, time) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index] = { ...newDateTimes[index], time };
    setDateTimes(newDateTimes);
  };

  const handleTreatmentTypeChange = (event) => {
    setTreatmentType(event.target.value);
  };

  const handleTreatmentNameChange = (event) => {
    setTreatmentName(event.target.value);
  };

  const handleTotalChange = (event) => {
    setTotal(parseInt(event.target.value, 10));
  };

  const handleDayChange = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day],
    );
  };

  const handleSubmit = async () => {
    try {
      const token = storage.get('TOKEN');
      if (!token) throw new Error('Token not found.');

      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) throw new Error('User data not available.');
      if (!patient?.id) throw new Error('Patient data or ID is not available.');

      const treatmentData = {
        name: treatmentName,
        total: total.toString(),
        remaining: (total - 1).toString(),
        status: 'ACTIVE',
        patient: patient.id,
        user,
        clinic: clinic.id,
        tokenAppointment: patient.tokenappointment,
      };

      if (treatmentType === 'sitting') {
        treatmentData.treatmentType = 'sitting';
        treatmentData.dateTimes = dateTimes
          .filter((dt) => dt.date && dt.time)
          .map((dt) => ({
            date: dt.date ? dayjs(dt.date).format('YYYY-MM-DD') : null,
            time: dt.time ? dayjs(dt.time).format('HH:mm') : null,
          }));
      } else if (treatmentType === 'visiting') {
        treatmentData.treatmentType = 'visiting';
        treatmentData.days = selectedDays;
        treatmentData.time = time;
      }

      console.log('Submitting treatment with data:', treatmentData);

      await dispatch(postTreatmentRequest(treatmentData));

      console.log('Treatment successfully submitted.');
    } catch (error) {
      console.error('Error submitting treatment:', error);
    }
  };

  return (
    <FormBox marginTop="20px">
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
          Treatment
        </Typography>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Treatment Type
          </Typography>
          <Tooltip title="Select the type of treatment (Visiting or Sitting)">
            <Select
              fullWidth
              value={treatmentType}
              onChange={handleTreatmentTypeChange}
              variant="outlined"
            >
              <MenuItem value="visiting">Visiting</MenuItem>
              <MenuItem value="sitting">Sitting</MenuItem>
            </Select>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ padding: '30px', position: 'relative', bottom: 40 }}
      >
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Treatment Name
          </Typography>
          <Tooltip title="Enter the treatment name required">
            <TextField
              variant="outlined"
              fullWidth
              value={treatmentName}
              onChange={handleTreatmentNameChange}
            />
          </Tooltip>
        </Grid>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Total
          </Typography>
          <Tooltip title="Enter the total number of sittings or visits required">
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              value={total}
              onChange={handleTotalChange}
            />
          </Tooltip>
        </Grid>

        {treatmentType === 'visiting' && (
          <>
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                When Visit
              </Typography>
              <Tooltip title="Select the days of the week for visits">
                <Grid
                  item
                  container
                  spacing={1}
                  sx={{ position: 'relative', bottom: '30px', left: '12%' }}
                >
                  {daysOfWeek.map((day, index) => (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(day)}
                            onChange={() => handleDayChange(day)}
                          />
                        }
                        label={day}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Tooltip>
            </Grid>
            <Grid item sx={{ position: 'relative', bottom: 50 }}>
              <Typography variant="h5" fontWeight="bold" sx={{ position: 'relative', top: 15 }}>
                Visit Time
              </Typography>
              <Tooltip title="Select the time for visits">
                <AppTimePicker
                  fullWidth
                  value={time}
                  onChange={(newTime) => setTime(dayjs(newTime).format('HH:mm'))} // Use dayjs to format time
                />
              </Tooltip>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Tooltip title="Save the visiting treatment">
                <Button variant="contained" onClick={handleSubmit}>
                  Save Visiting Treatment
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}

        {treatmentType === 'sitting' && (
          <>
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                Select Date
              </Typography>
            </Grid>

            <Grid item>
              <Stack direction="row" spacing={11}>
                <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                  Select Date
                </Typography>
                <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                  Select Time
                </Typography>
              </Stack>

              <Stack direction="row" spacing={19}>
                <Tooltip title="Select the date for sitting">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '35%' }}
                      onChange={(date) => handleDateChange(0, date)} // Handle date change
                    />
                  </LocalizationProvider>
                </Tooltip>
                <Tooltip title="Select the time for sitting">
                  <AppTimePicker
                    sx={{ width: '35%' }}
                    value={time}
                    onChange={(newTime) => handleTimeChange(0, dayjs(newTime).format('HH:mm'))}
                  />
                </Tooltip>
              </Stack>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Tooltip title="Save the sitting treatment">
                <Button variant="contained" onClick={handleSubmit}>
                  Save Sitting Treatment
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    </FormBox>
  );
}

export default Treatment;
