import React from 'react';
import Select from 'src/components/shared/Form/Select';
import { useTranslation } from 'react-i18next';
const LanguageSelector = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (onLanguageChange) {
      onLanguageChange(lng);
    }
  };
  return (
    <Select
      variant='outlined'
      fullWidth
      sx={{ position: 'relative', top: '10px' }}
      options={[
        { label: 'English', value: 'en' },
        { label: 'हिंदी', value: 'hindi' },
        { label: 'ગુજરાતી', value: 'gujarati' },
      ]}
      showNone={false}
      onChange={(val) => changeLanguage(val)}
      value={i18n.language}
    />
  );
};
export default LanguageSelector;