
import React from 'react';
import Welcome from '../components/patient/Welcome';
import Dashboard from '../components/DashBoard/Dashboard';
import { Container } from '@mui/material';
import SidebarToggle from 'src/components/App/RightSidebar/RightSidebarIcon';
function DashboardContainer() {
  return (
    <>
    
      {/* <Welcome /> */}
     
      <Container maxWidth="lg">
        <Dashboard />
      </Container>
     
    </>
  );
}
export default DashboardContainer;