import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import Prescription from "../../components/RightSidebar/Prescription";
import { Container } from "@mui/material";
function PrescriptionContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information/>
            <Container maxWidth='lg'>
           
                <Prescription />
            </Container>
        </>
    )
}
export default PrescriptionContainer;