import React from "react";
import { FormBox } from "./style";
import { Grid, Typography, Button, Tooltip } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function Case() {
    return (
        <>
             <FormBox sx={{ marginTop: '20px'}}>

<Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
<Typography
    variant="h4"
    color='#393A96'
    fontWeight='bold'
    sx={{  marginLeft: '2%' }}
>
    Case
</Typography>
                    <Grid item>
                        <Typography variant="h5" fontWeight='bold'>
                            Start Date
                        </Typography>
                        <Tooltip title="Select the start date for the case">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker  sx={{ width: '100%' }} />
                            </LocalizationProvider>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" fontWeight='bold'>
                            End Date
                        </Typography>
                        <Tooltip title="Select the end date for the case">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{ width: '100%' }} />
                            </LocalizationProvider>
                        </Tooltip>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                    <Tooltip title="Add the case date for patient">
                       
                        <Button variant='contained' sx={{ textAlign: 'center' }}>Add Case</Button>
                        </Tooltip>
                    </Grid>
                </Grid>

            </FormBox>
        </>
    )
}

export default Case;
