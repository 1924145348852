import React from "react";
import { FormBox, PaymentBox } from "./style";
import { Avatar, Grid, Typography, Box, Divider } from "@mui/material";
import { Button } from 'src/components/shared';
import Total from 'src/assets/images/Billpage/Total.svg'
import Paid from 'src/assets/images/Billpage/Paid.svg'
import Pedding from 'src/assets/images/Billpage/pending.svg'
import AppGrid from 'src/components/App/AppGrid'; 
import {payment} from 'src/mock/payment'
function Billing() {
    const columns = [
        {
            label: 'No',
            name: 'index',

        },
        {
            label: 'Client Name',
            name: 'name',
        },
        {
            label: 'Method',
            name: 'method',
        },
        {
            label: 'Date',
            name: 'date',
        },
        {
            label: 'Amount',
            name: 'amount',
        },
        {
            label: 'Invoice',
            name: '',
        }
    ]
    return (
        <>
            <FormBox >
                <Typography variant="h4" color='#393A96' fontWeight='bold' sx={{
                    position: 'relative', top: 20, left: '4%'
                }}>Billing and Payment</Typography>
                <Button variant='contained' sx={{position:'realtive',left:'90%'}}>Save</Button>
                {/* <Grid container spacing={2} justifyContent="center" sx={{ marginLeft: '40.5%', marginTop: '3px' }}>
                    <Button variant='contained'>Save</Button>
                </Grid> */}
                <PaymentBox>
                    <Grid container alignItems="center" sx={{
                        position:'relative',top:'15px'
                    }}>

                        <Avatar src={Total} sx={{ width: '28px', height: '28px', position: 'relative', top: '1px', left: '10%', border: '1px solid #B7B7B7', padding: '5px' }} />

                        <Typography variant="h4" color='#393A96' sx={{ position: 'relative', left: '11%' }}>Total</Typography>

                        <Typography variant="h3" fontWeight='bold' sx={{ position: 'relative', top: '35px' }}>$152.9k</Typography>
                       

                    </Grid>

                    <Divider orientation="vertical" sx={{
                        height: '11vh',
                        position: 'relative', right: '65%', bottom: '20px'
                    }} />

                    <Grid container alignItems="center" sx={{
                        position:'relative',top:'30px'
                    }}>

                        <Avatar src={Paid} sx={{ width: '28px', height: '28px', position: 'relative', bottom: '130px', left: '43%', border: '1px solid #B7B7B7', padding: '5px' }} />

                        <Typography variant="h4" color='#393A96' sx={{ position: 'relative', bottom: '130px', left: '44%' }}>Paid</Typography>

                        <Typography variant="h3" fontWeight='bold' sx={{ position: 'relative', bottom: '100px', left: '33%' }}>$109.3k</Typography>
                        <Divider orientation="vertical" sx={{
                            height: '11vh',
                            position: 'relative', bottom: '115px', left: '45%'
                        }} />

                    </Grid>

                    <Grid container alignItems="center" sx={{
                        position:'relative',top:'30px'
                    }}>

                        <Avatar src={Pedding} sx={{ width: '28px', height: '28px', position: 'relative', bottom: '180px', left: '74%', border: '1px solid #B7B7B7', padding: '5px' }} />

                        <Typography variant="h4" color='#393A96' sx={{ position: 'relative', bottom: '180px', left: '75%' }}>Pedding</Typography>

                        <Typography variant="h3" fontWeight='bold' sx={{ position: 'relative', bottom: '150px', left: '63%' }}>$43k</Typography>


                    </Grid>
                    <Typography variant="h4" color='#393A96' fontWeight='bold' sx={{
                        position: 'relative', bottom: '90px',
                    }}>Payment History</Typography>
                </PaymentBox>
                <Box
                    sx={{
                        marginTop:'50px',marginLeft:'4%'
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={11.5}>
                            <AppGrid
                                columns={columns}
                                data={payment}
                                selectableRows="none"
                                onFilterChange={() => { }}
                                appliedFilters={{}}
                                bulkActions={[]}
                                onBulkAction={() => { }}
                                onSort={() => { }}
                                onClearFilters={() => { }}
                                title=""
                                titleProps={{}}
                                pagingType="table"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FormBox>
        </>
    )
}
export default Billing;