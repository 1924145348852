import React from "react";
import Welcome from "../../components/patient/Welcome";
import Tag from "../../components/RightSidebar/Tag";
import { Container } from "@mui/material";
// import PatientInfo from "../../components/patient/PatientInfo";
import Information from "../../components/patient/Information";
function TaskContainer() {
    return (
        <>
            {/* <Welcome /> */}
         
            <Container maxWidth='lg'>
          
                <Tag />
            </Container>
        </>
    )
}
export default TaskContainer