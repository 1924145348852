const Clinics = [{
    "clinicName": "Rangam Clinic",
    
    "address": "123 Health St, Wellness City",
    "contactNumber": "+1234567890",
    "emailAddress": "contact@healthclinic.com",
    "registrationNumber": "REG123456",
    "city": "Wellness City",
    "state": "Healthy State",
    "country": "Healthland",
    "postCode": "12345",
    "description": "We provide the best health services in town.",
    
}

];

export default Clinics;