import React, { useState } from 'react';
import { PatientName, AddressName, InfoTypography, DataTypography } from './styles';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Edit from 'src/assets/images/welcome/EditIcon.svg';
import { StyledNotificationsIcon } from './styles';
import { palette } from 'src/config/theme';
import Button from 'src/components/shared/Button';
import { useSelector } from 'react-redux';
function EditModal({ onClose }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '400px',
        }}
      >
        <Typography variant="h6">Edit Patient Info</Typography>
        {/* Add your form or content for editing patient info here */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function Infomation() {
  const clinic = useSelector((state) => state.app.clinics);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const theme = useTheme();

  const handleEditClick = () => {
    setIsEditModalOpen(true);
    // Alternatively, if you want to navigate to an edit page:
    // navigate(`/${clinic.clinicId}/${clinic.doctorId}/editPatientInfo`);
  };

  return (
    <>
      <Box
        sx={{
          padding: '20px',
          width: '86%',
          backgroundColor: 'white',
          borderRadius: '16px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          bottom: '20px',
          right: '2.5%',
          justifyContent: 'space-between',
          [theme.breakpoints.down('lg')]: {
            height: '20vh',
          },
          [theme.breakpoints.down('md')]: {
            height: '20vh',
          },
          [theme.breakpoints.down('sm')]: {
            height: '20vh',
          },
        }}
      >
        <Grid container direction="column">
          <Box p={1} sx={{ display: 'flex', alignItems: 'start' }}>
            <Avatar sx={{ marginRight: 2 }} />
            <Box>
              <PatientName variant="h6">Ansh Ashok Rathod</PatientName>
              <AddressName variant="subtitle2">1338, Emily Flat, Reginaldtown</AddressName>
            </Box>
            <Box>
              <Stack
                direction={{ sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems="center"
                spacing={2}
                position="relative"
                left="35%"
              >
                <Box>
                  <InfoTypography variant="body1">Age</InfoTypography>
                  <DataTypography variant="body2">8 Year</DataTypography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                />
                <Box>
                  <InfoTypography variant="body1">Sex</InfoTypography>
                  <DataTypography variant="body2">Male</DataTypography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                />
                <Box>
                  <InfoTypography variant="body1">Weight</InfoTypography>
                  <DataTypography variant="body2">60 kg</DataTypography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                />
                <Box>
                  <InfoTypography variant="body1">Height</InfoTypography>
                  <DataTypography variant="body2">179 cm</DataTypography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                />
                <Box>
                  <InfoTypography variant="body1">Basic</InfoTypography>
                  <DataTypography variant="body2">Fever Etc.</DataTypography>
                </Box>
              </Stack>
            </Box>
            <Avatar
              src={Edit}
              sx={{ position: 'relative',right:'-17%' }}
              onClick={handleEditClick}
              style={{ cursor: 'pointer' }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              position: 'relative',
              left: '2%',
            }}
          >
            <StyledNotificationsIcon sx={{ marginRight: 1 }} />
            <Typography
              variant="body1"
              sx={{
                color: palette.primary.main,
                fontWeight: 'bold',
              }}
            >
              Outstanding / Missed 2 Followup / Referred or some important notes
            </Typography>
          </Box>
        </Grid>
      </Box>

      {/* {isEditModalOpen && <EditModal onClose={() => setIsEditModalOpen(false)} />} */}
    </>
  );
}

export default Infomation;
