import React from 'react';
import { Drawer, Box, List, ListItem, ListItemText, Avatar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import itemList from './ItemList'; // Assuming itemList is correctly imported and structured

function RightSidebar({ isOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = useSelector((state) => state.auth?.permissions);
  const clinic = useSelector((state) => state.app?.clinics);
  const selectedPatient = useSelector((state) => state.selectedPatient);
  const { patient } = location.state || {};

  const handleItemClick = (item) => {
    let route = item.route;
    if (item.route.includes(':patientId')) {
      route = item.route.replace(':patientId', selectedPatient?.id || '');
    }
    if (route.endsWith('/')) {
      route = route.slice(0, -1);
    }
    const finalRoute = `/${clinic.clinicId}/${clinic.doctorId}${route}/${patient.id}`;
  
    navigate(finalRoute, { state: { patient: selectedPatient } });
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleSidebar}
      swipeAreaWidth={30}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        },
      }}
    >
      <Box
        sx={{ width: 230 }}
        role="presentation"
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
      >
        <List>
          {itemList.map((item, index) => (
            (!item.permission || permissions?.[item.permission] === 'yes') && (
              <ListItem
                button
                key={index}
                onClick={() => handleItemClick(item)}
                sx={{ display: 'flex', alignItems: 'flex-start' }}
              >
                <Avatar src={item.icon} />
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#393A96' } }}
                />
              </ListItem>
            )
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default RightSidebar;
