import Token from 'src/assets/images/RightSidebar/Token.svg';
import Appointment from 'src/assets/images/RightSidebar/Appointment.svg';
import Task from 'src/assets/images/RightSidebar/Task.svg';
import Bill from 'src/assets/images/RightSidebar/Bill.svg';
import Prescription from 'src/assets/images/RightSidebar/Prescription.svg';
import Notes from 'src/assets/images/RightSidebar/Notes.svg';
import Case from 'src/assets/images/RightSidebar/Case.svg';
import Pharmacy from 'src/assets/images/RightSidebar/Pharmacy.svg';
import Lab from 'src/assets/images/RightSidebar/Lab.svg';
import Message from 'src/assets/images/RightSidebar/Message.svg';
import Staffs from 'src/assets/images/RightSidebar/Staffs.svg';
import Treatment from 'src/assets/images/RightSidebar/Treatment.svg';
import Area from 'src/assets/images/RightSidebar/Area.svg';
import Followup from 'src/assets/images/RightSidebar/Followup.svg';
// import Clinic from 'src/assets/images/RightSidebar/Clinic.svg';
import Reports from 'src/assets/images/RightSidebar/Reports.svg'; 
import NewPatients from 'src/assets/images/RightSidebar/NewPatients.svg'; 
import SpecialNotes from 'src/assets/images/RightSidebar/SpecialNotes.svg';
import PatientInfo from 'src/assets/images/RightSidebar/PatientInfo.svg'; 

const iconConfig = {
    token: Token,
    appointment: Appointment,
    task: Task,
    bill: Bill,
    prescription: Prescription,
    notes: Notes,
    case: Case,
    pharmacy: Pharmacy,
    lab: Lab,
    message: Message,
    area: Area,
    staffs: Staffs,
    // clinic: Clinic,
    treatment: Treatment,
    followup: Followup,
    reports: Reports,
    newpatients: NewPatients,
    specialnotes: SpecialNotes,
    patientinfo: PatientInfo
};

export default iconConfig;
