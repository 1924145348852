import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider, useDispatch, useSelector } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store, { history } from 'src/store';
// import { BrowserRouter as Router } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
// import i18n (needs to be bundled ;))
import './i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <React.Suspense>
          <App />
        </React.Suspense>
      </Router>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
