import React, { useState } from "react";
import { ProfileBackBox, ProfileBox } from "./style"
import { Divider, Grid, Typography, Stack, Avatar } from "@mui/material";
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone';
import InputComponent from "src/components/shared/Form/Input";
import Select from "src/components/shared/Form/Select";
import Textarea from "src/components/shared/Form/Textarea";
import { Button } from 'src/components/shared';

function Profile() {
    const [image, setImage] = useState(null);

    const handleImageUpload = (event) => {
        console.log("File selected");
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                console.log("File read");
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleIconClick = () => {
        console.log("Icon clicked");
        document.getElementById('fileInput').click();
    };

    return (
        <>
            <ProfileBox height='260vh'>
                <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center'
                    sx={{ position: 'relative', top: '15px' }} >
                    Profile
                </Typography>
                <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                <Grid container direction="column" spacing={2} sx={{ padding: '30px' }} justifyContent="center" alignItems="center">
                    <Grid item>
                        <ProfileBackBox>
                            <Avatar src={image} sx={{ width: 100, height: 100, marginTop: '80px' }} />
                            <AddAPhotoTwoToneIcon
                                onClick={handleIconClick}
                                sx={{ position: 'relative', top: '80px', right: "30px", color: '#393A96', cursor: 'pointer' }}
                            />
                            <input
                                id="fileInput"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </ProfileBackBox>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={2} sx={{ padding: '30px', position: 'relative', bottom: "30px" }}>
                    <Grid item sx={{position: 'relative',top:"20px"}}>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Name
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Professional Title
                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <Select variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>



                    <Grid item sx={{position: 'relative',top:"20px"}}>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }} >
                                Specialization
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Year Of Exprience

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <Select variant="outlined" sx={{ width: '45%' }} />
                            <Select variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Qualification
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Service

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <Select variant="outlined" sx={{ width: '45%' }} />
                            <Select variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                LinkedIn
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Facebook

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Instagram
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Twitter
                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Professional Website


                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Other Relevant Links

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={11}>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                                Online Consultation



                            </Typography>
                            <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>

                                Special Procedure

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <Select variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={35}>
                            <Typography variant="h5" fontWeight="bold">
                                Hospital Location



                            </Typography>
                            <Typography variant="h5" fontWeight="bold" >


                                Emergency No

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item >
                        <Typography variant="h5" fontWeight='bold'>
                            Emergency Situation
                        </Typography>

                        <Textarea variant='outlined' />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" fontWeight='bold'>
                            Professional Biography
                        </Typography>
                        <Textarea variant='outlined' />

                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={43}>
                            <Typography variant="h5" fontWeight="bold">
                                Email Id
                            </Typography>
                            <Typography variant="h5" fontWeight="bold">


                                Contact No

                            </Typography>
                        </Stack>


                        <Stack direction="row" spacing={11}>
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                            <InputComponent variant="outlined" sx={{ width: '45%' }} />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Typography variant="h5" fontWeight='bold'>
                            Hospital Address
                        </Typography>
                        <Textarea variant='outlined' />

                    </Grid>
                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
                        <Button variant='contained' sx={{ textAlign: 'center' }}>Edit Profile</Button>
                    </Grid>
                </Grid>
            </ProfileBox >

        </>
    )
}
export default Profile