import React, { useState } from 'react';
import { ClinicBox, ProfileBackBox } from './style';
import {
  Avatar,
  Select,
  MenuItem,
  Switch,
  Grid,
  Typography,
  Stack,
  IconButton,
  Box,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Button as MuiButton,
  Tabs,
  Tab,
  AppBar,
} from '@mui/material';
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Button } from 'src/components/shared';
import qrCodeImage from 'src/assets/images/auth/qrcode.png';
import { useForm, Controller } from 'react-hook-form';
import InputAutocomplete from 'src/components/shared/Form/AutoComplete';

import AppTimePicker from 'src/components/shared/Form/Timepicker';

const predefinedTitles = {
  'facebook.com': 'Facebook',
  'twitter.com': 'Twitter',
  'linkedin.com': 'LinkedIn',
  'instagram.com': 'Instagram',
};
const spokenLanguagesList = [
  { id: 1, name: 'English' },
  { id: 2, name: 'Hindi' },
  { id: 3, name: 'Gujarati' },
  // Add more languages as needed
];
const countryCodes = [
  { label: 'India', value: '+91', flag: '🇮🇳' },
  { label: 'Indonesia', value: '+62', flag: '🇮🇩' },
  { label: 'Iran', value: '+98', flag: '🇮🇷' },
  { label: 'Iraq', value: '+964', flag: '🇮🇶' },
  { label: 'Ireland', value: '+353', flag: '🇮🇪' },
  { label: 'Isle of Man', value: '+44', flag: '🇮🇲' },
  // Add more countries as needed
];
function Profile() {
  const [image, setImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [appointmentAccepting, setAppointmentAccepting] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: '',
    fullName: '',
    displayName: '',
    profilePic: '',
    bannerPic: '',
    address: '',
    languages: '',
    about: '',
  });
  // const [loginInfo, setLoginInfo] = useState({
  //   email: '',
  //   contact: '',
  //   username: '',
  //   otp: '',
  //   pin: '',
  //   password: '',
  //   biometric: '',
  // });
  const [availability, setAvailability] = useState([]);
  const [devices, setDevices] = useState([
    { name: '', tokenId: '', activeSince: '', lastActive: '' },
  ]);
  const [educationalInfo, setEducationalInfo] = useState({
    school: '',
    college: '',
    specialization: '',
  });
  const [professionalInfo, setProfessionalInfo] = useState({
    experience: '',
    license: '',
    notes: '',
    challenges: '',
    awards: '',
    specialProcedures: '',
    requestToPatients: '',
  });
  const [consultingOptions, setConsultingOptions] = useState({
    teleMedicine: false,
    inClinic: false,
    inHome: false,
    homeVisit: false,
    emergency: false,
    inPesonalInfo: false,
    home: false,
  });
  const [settings, setSettings] = useState({
    avgTime: '',
    gapBetweenAppointments: '',
    collectPatientInfo: false,
    caseValidity: '',
    newCaseFee: '',
    oldCaseFee: '',
    unavailabilityNote: '',
    insuranceAccepting: '',
    govtYojana: '',
    treatmentClinic: '',
    treatmentHome: '',
    publishProfile: false,
    acceptPatientsOnline: false,
  });
  const [seoInfo, setSeoInfo] = useState({
    title: '',
    metaKeywords: '',
    description: '',
    footerText: '',
    topKeywords: '',
  });
  const [links, setLinks] = useState([{ type: '', title: '', url: '', active: false }]);
  const [photos, setPhotos] = useState([{ title: '', url: '', link: '' }]);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [showNewLinkForm, setShowNewLinkForm] = useState(false);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [openingHours, setOpeningHours] = useState([{ day: 'Monday', startTime: '', endTime: '' }]);
  const linkValue = watch('link');
  const [linkForms, setLinkForms] = useState([{ linkTitle: '', link: '' }]);
  const handleAddLinkForm = () => {
    setLinkForms([...linkForms, { linkTitle: '', link: '' }]);
  };

  const handleRemoveLinkForm = (index) => {
    const updatedForms = [...linkForms];
    updatedForms.splice(index, 1);
    setLinkForms(updatedForms);
  };

  React.useEffect(() => {
    if (linkValue) {
      const hostname = new URL(linkValue).hostname.replace('www.', '');
      const title = predefinedTitles[hostname];
      if (title) {
        setValue('linkTitle', title); // Automatically set the title if it's in the predefined list
      }
    }
  }, [linkValue, setValue]);

  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
  };
  const handleOpeningHourChange = (index, field, value) => {
    const newOpeningHours = [...openingHours];
    newOpeningHours[index][field] = value;
    setOpeningHours(newOpeningHours);
  };

  const handleAddOpeningHour = () => {
    if (openingHours.length < 7) {
      setOpeningHours([...openingHours, { day: '', startTime: '', endTime: '' }]);
    } else {
      console.log('You can only add up to 7 opening hours.');
    }
  };

  const handleRemoveOpeningHour = (index) => {
    setOpeningHours(openingHours.filter((_, i) => i !== index));
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setProfileInfo({ ...profileInfo, [type]: reader.result });
    };
  };

  const [images, setImages] = useState([]);
  const [photoDetails, setPhotoDetails] = useState({ type: '0' });

  const handleImageUploaded = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handlePhotoDetailChange = (field, value) => {
    setPhotoDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const onSubmit = (data) => {
    console.log(data);
  };
  const handleAddDevice = () => {
    setDevices([...devices, { name: '', tokenId: '', activeSince: '', lastActive: '' }]);
  };
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  // const handleLanguageChange = (language) => {
  //     if (selectedLanguages.includes(language)) {
  //         setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
  //     } else {
  //         setSelectedLanguages([...selectedLanguages, language]);
  //     }
  // };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setConsultingOptions({ ...consultingOptions, [name]: checked });
  };

  // const handleTextFieldChange = (event) => {
  //     const { name, value } = event.target;
  //     setConsultingOptions({ ...consultingOptions, [name]: value });
  // };
  const handleRemoveDevice = (index) => {
    const newDevices = [...devices];
    newDevices.splice(index, 1);
    setDevices(newDevices);
  };

  const handleDeviceChange = (index, field, value) => {
    const newDevices = [...devices];
    newDevices[index][field] = value;
    setDevices(newDevices);
  };

  const handleAddLink = () => {
    setLinks([...links, { type: '', title: '', url: '', active: false }]);
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const handleAddPhoto = () => {
    setPhotos([...photos, { title: '', url: '', link: '' }]);
  };

  const handleRemovePhoto = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };

  const handlePhotoChange = (index, field, value) => {
    const newPhotos = [...photos];
    newPhotos[index][field] = value;
    setPhotos(newPhotos);
  };
  const handleIconClick = (inputId) => {
    document.getElementById(inputId).click();
  };
  const handleNext = () => {
    setSelectedTab(selectedTab + 1); // Move to the next tab
  };
  const handlePrevious = () => {
    setSelectedTab(selectedTab - 1); // Move to the previous tab
  };
  const [consultingsOptions, setConsultingsOptions] = useState({
    caseValidityInDays: '',
    newCaseConsultingFees: '',
    oldCaseConsultingFees: '',
  });

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/\D/g, ''); // Remove non-digit characters

    setConsultingOptions((prevOptions) => ({
      ...prevOptions,
      [name]: newValue,
    }));
  };

  const [isPhoneNumberLinkVisible, setIsPhoneNumberLinkVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0].value);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [consultingOption, setConsultingOption] = useState('NA');
  const [averagePatientTimeOptions] = useState([5, 10, 15, 20, 30]); // Define average patient time options
  const [gapBetweenAppointmentsOptions] = useState([5, 10, 15, 20, 30]);
  const handleConsultingOptionChange = (option) => {
    setConsultingOption(option);
  };

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    contact: '',
    username: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    contact: '',
    username: '',
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateContact = (contact) => {
    const re = /^[0-9\b]+$/;
    return re.test(contact);
  };

  const validateUsername = (username) => {
    return username.trim().length > 0; // Ensure username is not empty
  };

  const handleChange = (field, value) => {
    setLoginInfo({ ...loginInfo, [field]: value });

    switch (field) {
      case 'email':
        setErrors({
          ...errors,
          email: validateEmail(value) ? '' : 'Invalid email address',
        });
        break;
      case 'contact':
        setErrors({
          ...errors,
          contact: validateContact(value) ? '' : 'Contact number must be digits only',
        });
        break;
      case 'username':
        setErrors({
          ...errors,
          username: validateUsername(value) ? '' : 'Username cannot be empty',
        });
        break;
      default:
        break;
    }
  };
  return (
    <ClinicBox>
      <Typography
        variant="h3"
        color="#393A96"
        fontWeight="bold"
        textAlign="center"
        sx={{ position: 'relative', top: '15px' }}
      >
        Doctor Profile
      </Typography>
      <Divider sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ padding: '30px' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <ProfileBackBox>
            <Avatar src={image} sx={{ width: 100, height: 100, marginTop: '80px' }} />
            <AddAPhotoTwoToneIcon
              onClick={() => handleIconClick('profilePicInput')}
              sx={{
                position: 'relative',
                top: '80px',
                right: '30px',
                color: '#393A96',
                cursor: 'pointer',
              }}
            />
            <input
              id="profilePicInput"
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleImageUpload(e, setImage)}
            />
          </ProfileBackBox>
        </Grid>
      </Grid>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Personal Info" />
          <Tab label="Login Info" />
          <Tab label="Availability" />
          <Tab label="Linked Devices" />
          <Tab label="Education" />
          <Tab label="Professional Info" />
          <Tab label="Consulting Options" />
          <Tab label="SEO" />
          <Tab label="Links" />
          <Tab label="Photos" />
        </Tabs>
      </AppBar>

      {selectedTab === 0 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Title
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  value={profileInfo.title}
                  onChange={(e) => setProfileInfo({ ...profileInfo, title: e.target.value })}
                >
                  <MenuItem value="Dr.">Dr.</MenuItem>
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Miss">Miss</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  {/* <MenuItem value="Nurse">Nurse</MenuItem>
                                    <MenuItem value="Therapist">Therapist</MenuItem> */}
                  {/* Add more titles as needed */}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Full Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={profileInfo.fullName}
                  onChange={(e) => setProfileInfo({ ...profileInfo, fullName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Languages Spoken
                </Typography>
                <InputAutocomplete
                  variant="outlined"
                  fullWidth
                  options={[
                    { label: 'English', value: 'English' },
                    { label: 'Gujarati', value: 'Gujarati' },
                    { label: 'Hindi', value: 'Hindi' },
                  ]}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Home Address
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={profileInfo.address}
                  onChange={(e) => setProfileInfo({ ...profileInfo, address: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  About
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={profileInfo.about}
                  onChange={(e) => setProfileInfo({ ...profileInfo, about: e.target.value })}
                />
              </Grid>
              <Grid container spacing={2} justifyContent="end" sx={{ marginTop: '10px' }}>
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

{selectedTab === 1 && (
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Box component="form">
          <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold">
                Email Address
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={loginInfo.email}
                onChange={(e) => handleChange('email', e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold">
                Contact Number
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={loginInfo.contact}
                onChange={(e) => handleChange('contact', e.target.value)}
                error={!!errors.contact}
                helperText={errors.contact}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold">
                Username
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={loginInfo.username}
                onChange={(e) => handleChange('username', e.target.value)}
                error={!!errors.username}
                helperText={errors.username}
              />
            </Grid>

            <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    !!errors.email || !!errors.contact || !!errors.username
                  }
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )}

      {selectedTab === 2 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          <Typography variant="h5" fontWeight="bold">
            Opening Hours
          </Typography>
          <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
            {openingHours.map((openingHour, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Day
                  </Typography>
                  <Select
                    sx={{ position: 'relative', top: '15px' }}
                    fullWidth
                    variant="outlined"
                    value={openingHour.day}
                    onChange={(e) => handleOpeningHourChange(index, 'day', e.target.value)}
                  >
                    <MenuItem value="Monday">Monday</MenuItem>
                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                    <MenuItem value="Thursday">Thursday</MenuItem>
                    <MenuItem value="Friday">Friday</MenuItem>
                    <MenuItem value="Saturday">Saturday</MenuItem>
                    <MenuItem value="Sunday">Sunday</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Start Time
                  </Typography>
                  <AppTimePicker />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Start Time
                  </Typography>
                  <AppTimePicker />
                </Grid>
                <Grid item xs={2} sx={{ position: 'relative', top: '30px' }}>
                  <IconButton onClick={() => handleRemoveOpeningHour(index)}>
                    <RemoveCircle />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={2} sx={{ position: 'relative', left: '25px' }}>
              <Button
                startIcon={<AddCircle />}
                onClick={handleAddOpeningHour}
                sx={{ position: 'relative', right: '50px' }}
              >
                Add Opening Hour
              </Button>
              <Grid item sx={{position:'relative' ,right:'50px'}}>
              <Switch
                  checked={appointmentAccepting}
                  onChange={(e) => setAppointmentAccepting(e.target.checked)}
                />
                <Typography variant="h6" fontWeight="bold" display="inline" ml={1}>
                  Accepting Appointments
                </Typography>
                
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginTop: 2, position: 'relative', bottom: '30px', right: '10px' }}
            ></Grid>
            <Grid container spacing={8} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {selectedTab === 3 && !isPhoneNumberLinkVisible ? (
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{ padding: '30px', maxWidth: '600px', margin: 'auto' }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Typography
                variant="body2"
                textAlign="center"
                color="primary"
                style={{ marginTop: '20px', cursor: 'pointer' }}
                onClick={() => setIsPhoneNumberLinkVisible(true)}
              >
                Link with phone number
              </Typography>
              <img
                src={qrCodeImage}
                alt="WhatsApp Web QR Code"
                style={{ width: '100%', height: 'auto' }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={8} justifyContent="flex-end" sx={{ marginLeft: 5 }}>
            <Grid item>
              <Button variant="contained" onClick={handlePrevious}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : selectedTab === 3 && isPhoneNumberLinkVisible ? (
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{ padding: '30px', maxWidth: '600px', margin: 'auto' }}
        >
          <Typography
            variant="body2"
            color="primary"
            textAlign="center"
            onClick={() => setIsPhoneNumberLinkVisible(false)}
            style={{ cursor: 'pointer', marginTop: '20px' }}
          >
            Link with QR code
          </Typography>
          <Typography variant="body1" textAlign="center">
            Select a country and enter your phone number.
          </Typography>
          <Box component="form">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  select
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  fullWidth
                >
                  {countryCodes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.flag} {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={8} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    /* Handle phone number link */
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={8} justifyContent="flex-end" sx={{ marginLeft: 5 }}>
            <Grid item>
              <Button variant="contained" onClick={handlePrevious}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {selectedTab === 4 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  School Information
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={professionalInfo.school}
                  onChange={(e) =>
                    setProfessionalInfo({ ...professionalInfo, school: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  College Information
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={professionalInfo.college}
                  onChange={(e) =>
                    setProfessionalInfo({ ...professionalInfo, college: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Specialisation Information
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={professionalInfo.specialisation}
                  onChange={(e) =>
                    setProfessionalInfo({ ...professionalInfo, specialisation: e.target.value })
                  }
                />
              </Grid>

              <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
                <Grid item>
                  <Button variant="contained" onClick={handlePrevious}>
                    Previous
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={handleNext}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
      {selectedTab === 5 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    Experience
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.experience}
                    onChange={(e) =>
                      setProfessionalInfo({ ...professionalInfo, experience: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    License
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.license}
                    onChange={(e) =>
                      setProfessionalInfo({ ...professionalInfo, license: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Notes
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.notes}
                    onChange={(e) =>
                      setProfessionalInfo({ ...professionalInfo, notes: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Challenges
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.challenges}
                    onChange={(e) =>
                      setProfessionalInfo({ ...professionalInfo, challenges: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Awards
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.awards}
                    onChange={(e) =>
                      setProfessionalInfo({ ...professionalInfo, awards: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Special Procedures
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={professionalInfo.specialProcedures}
                    onChange={(e) =>
                      setProfessionalInfo({
                        ...professionalInfo,
                        specialProcedures: e.target.value,
                      })
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight='bold'>Requests to Patients</Typography>
                                    <TextField fullWidth variant="outlined" value={professionalInfo.requestToPatients} onChange={(e) => setProfessionalInfo({ ...professionalInfo, requestToPatients: e.target.value })} />
                                </Grid> */}
                <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
                  <Grid item>
                    <Button variant="contained" onClick={handlePrevious}>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleNext}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      {selectedTab === 6 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.notForUnavailablePatients}
                        onChange={handleCheckboxChange}
                        name="notForUnavailablePatients"
                      />
                    }
                    label="Not for Patients on unavailability"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.insuranceAccepting}
                        onChange={handleCheckboxChange}
                        name="insuranceAccepting"
                      />
                    }
                    label="Insurance Accepting"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.governmentYojana}
                        onChange={handleCheckboxChange}
                        name="governmentYojana"
                      />
                    }
                    label="Government Yojana"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.treatmentAtClinic}
                        onChange={handleCheckboxChange}
                        name="treatmentAtClinic"
                      />
                    }
                    label="Treatment @ Clinic"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.treatmentAtHome}
                        onChange={handleCheckboxChange}
                        name="treatmentAtHome"
                      />
                    }
                    label="Treatment @ Home"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.publishProfileOnline}
                        onChange={handleCheckboxChange}
                        name="publishProfileOnline"
                      />
                    }
                    label="Publish Profile Online"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.acceptPatientsOnline}
                        onChange={handleCheckboxChange}
                        name="acceptPatientsOnline"
                      />
                    }
                    label="Accept Patients Online"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.inteleMedicine}
                        onChange={handleCheckboxChange}
                        name="inteleMedicine"
                      />
                    }
                    label="TeleMedicines"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.inHome}
                        onChange={handleCheckboxChange}
                        name="inHome"
                      />
                    }
                    label="In Person at Home"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.emergency}
                        onChange={handleCheckboxChange}
                        name="emergency"
                      />
                    }
                    label="Emergency Availability"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.inClinic}
                        onChange={handleCheckboxChange}
                        name="inClinic"
                      />
                    }
                    label="In Person at Clinic"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consultingOptions.inPesonalInfo}
                        onChange={handleCheckboxChange}
                        name="inPesonalInfo"
                      />
                    }
                    label="Collect Patients Info"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    Case Validity in Days
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="caseValidityInDays"
                    value={consultingOptions.caseValidityInDays}
                    onChange={handleTextFieldChange}
                    placeholder="Enter case validity in days"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    New Case Consulting Fees
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="newCaseConsultingFees"
                    value={consultingOptions.newCaseConsultingFees}
                    onChange={handleTextFieldChange}
                    placeholder="Enter new case consulting fees"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    Old Case Consulting Fees
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="oldCaseConsultingFees"
                    value={consultingOptions.oldCaseConsultingFees}
                    onChange={handleTextFieldChange}
                    placeholder="Enter old case consulting fees"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    Gap between Two Appointments
                  </Typography>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={consultingOptions.gapBetweenAppointments}
                    onChange={(e) =>
                      setConsultingOptions({
                        ...consultingOptions,
                        gapBetweenAppointments: e.target.value,
                      })
                    }
                  >
                    {gapBetweenAppointmentsOptions.map((option) => (
                      <MenuItem key={option} value={option}>{`${option} min`}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h5" fontWeight="bold">
                    One Patient Average Taking
                  </Typography>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={consultingOptions.averagePatientTime}
                    onChange={(e) =>
                      setConsultingOptions({
                        ...consultingOptions,
                        averagePatientTime: e.target.value,
                      })
                    }
                  >
                    {averagePatientTimeOptions.map((option) => (
                      <MenuItem key={option} value={option}>{`${option} min`}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid container spacing={8} justifyContent="flex-end" sx={{ marginTop: '-5px' }}>
                  <Grid item>
                    <Button variant="contained" onClick={handlePrevious}>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleNext}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
      {selectedTab === 7 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Profile Title
                </Typography>
                <Controller
                  name="profileTitle"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Profile Meta Keywords
                </Typography>
                <Controller
                  name="profileMetaKeywords"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Footer Text
                </Typography>
                <Controller
                  name="footerText"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Top Keywords
                </Typography>
                <Controller
                  name="topKeywords"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Profile Description
                </Typography>
                <Controller
                  name="profileDescription"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth multiline rows={4} />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} justifyContent="end">
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      {selectedTab === 8 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          {linkForms.map((form, index) => (
            <Box key={index} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Link Title
                  </Typography>
                  <Controller
                    name={`linkTitle-${index}`}
                    control={control}
                    defaultValue={form.linkTitle}
                    render={({ field }) => <TextField {...field} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Link
                  </Typography>
                  <Controller
                    name={`link-${index}`}
                    control={control}
                    defaultValue={form.link}
                    render={({ field }) => <TextField {...field} fullWidth />}
                  />
                </Grid>
                {index === linkForms.length - 1 && (
                  <Grid item xs={2}>
                    <Button startIcon={<AddCircle />} onClick={handleAddLinkForm}>
                      Add New Link
                    </Button>
                  </Grid>
                )}
                {index > 0 && (
                  <Grid item xs={2}>
                    <Button startIcon={<AddCircle />} onClick={() => handleRemoveLinkForm(index)}>
                      Remove Link
                    </Button>
                  </Grid>
                )}
              </Grid>
              {index === linkForms.length - 1 && (
                <Grid container spacing={8} justifyContent="flex-end">
                  <Grid item>
                    <Button variant="contained" onClick={handlePrevious}>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleNext}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          ))}
        </Grid>
      )}

      {selectedTab === 9 && (
      <Grid container spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h5" fontWeight="bold">
          Photos
        </Typography>
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Upload Photos
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={handleImageUploaded}
            />
          </Button>

          <ImageList cols={3} sx={{ marginTop: '20px' }}>
            {images.map((image, index) => (
              <ImageListItem key={index}>
                <img src={image} alt={`uploaded-${index}`} />
              </ImageListItem>
            ))}
          </ImageList>


          
            <Grid container spacing={8} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained">save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ClinicBox>
  );
}

export default Profile;
