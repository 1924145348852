import React from 'react';
import Welcome from '../components/patient/Welcome';
import Infomation from '../components/patient/Information';
import Billing from '../components/DashBoard/Billing';
import { Container } from '@mui/material';
function BillingContainer() {
  return (
    <>
      {/* <Welcome /> */}
      <Infomation />
      <Container maxWidth="lg">
        <Billing />
      </Container>
    </>
  );
}
export default BillingContainer;