import Icon from 'src/components/App/Icon';

export const menuItems = [
  {
    title: 'OPD',
    link: '',
    icon: <Icon name="opd" />,
  },
  {
    title: 'Dashboard',
    link: '/performance',
    icon: <Icon name="dashboard" />,
  },
  {
    key: 'appointment',
    title: 'appointment',
    link: '/appointment',
    icon: <Icon name="appointment" />,
    permission: 'appointment',
  },
  {
    key: 'patients',
    title: 'Patients',
    link: '/patients',
    icon: <Icon name="patients" />,
    permission: 'patients',
  },
  {
    key: 'staffs',
    title: 'Staffs',
    link: '/staffs',
    icon: <Icon name="staff" />,
    permission: 'staff',
  },
  {
    key: 'tasks',
    title: 'Tasks',
    link: '/tasks',
    icon: <Icon name="tasks" />,
    permission: 'task',
  },
  {
    key: 'clinics',
    title: 'Clinics',
    link: '/clinics',
    icon: <Icon name="clinics" />,
    permission: 'clinic',
  },

  {
    key: 'billing',
    title: 'Billing & payments',
    link: '/billing',
    icon: <Icon name="billing" />,
    permission: 'billing',
  },
];
