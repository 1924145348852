import React, { useEffect, useState } from 'react';
import { Grid, Typography, Tooltip, Box } from '@mui/material';
import { Button } from 'src/components/shared';
import SwitchComponent from 'src/components/shared/Form/Switch';
import Textarea from 'src/components/shared/Form/Textarea';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { postMedicalNotesRequest, fetchNotesRequest } from '../../store/appActions';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { FormBox, ViewBox } from './style';
import { useNavigate, useLocation } from 'react-router-dom';

function Notes() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const medicalRecords = useSelector((state) => state.app.medicalRecords);
    // console.log('Medical Records:', medicalRecords);

  const location = useLocation();
  const navigate = useNavigate();
  const [latestNotes, setLatestNotes] = useState([]);
  const { patient } = location.state || {};

  // Fetch notes when patient ID is available
  useEffect(() => {
    if (patient?.id) {
      dispatch(fetchNotesRequest(patient.id));
    }
  }, [dispatch, patient]);

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) throw new Error('User data not available.');
      if (!patient?.id) throw new Error('Patient data or ID is not available.');
      if (!values.notes) throw new Error('Notes are required.');

      const medicalRecord = {
        ...values,
        patient: patient.id,
        tokenappointment: patient.tokenappointment,
        user,
        recordType: 'note',
        status: 'ACTIVE',
        patientView: values.patientView ? 'Public' : 'Private',
        date: new Date().toISOString(),
        time: new Date().toTimeString().split(' ')[0],
      };

      const response = await new Promise((resolve, reject) => {
        dispatch(postMedicalNotesRequest({ data: medicalRecord, resolve, reject }));
      });

      toast.success(response.message || 'Notes created successfully');
      resetForm();
      dispatch(fetchNotesRequest(patient.id));
    } catch (error) {
      toast.error('Failed to add Notes: ' + (error.message || error));
    }
  };

  // Filter and set latest notes
  useEffect(() => {
    console.log('Medical Records:', medicalRecords); // Check the structure
  
    if (medicalRecords && Array.isArray(medicalRecords)) {
      const filteredNotes = medicalRecords
        .filter((record) => record && record.recordType === 'note')
        .slice(0, 5)
        .map((record) => ({
          notes: record?.notes || 'N/A', // Use optional chaining
          date: record?.date ? record.date.split('T')[0] : 'N/A', 
        }));
  
      console.log('Filtered Notes:', filteredNotes);
      setLatestNotes(filteredNotes);
    } else {
      console.error('Medical records is not an array or is undefined');
    }
  }, [medicalRecords]);
  

  const handleNavigation = (path) => {
    if (patient) {
      navigate(path, { state: { patient } });
    } else {
      console.error('Patient data is missing');
    }
  };

  if (!patient?.id) {
    return <Typography variant="h6">Patient data not found.</Typography>;
  }

  return (
    <>
      <FormBox sx={{ marginTop: '20px' }}>
        <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
          <Grid item>
            <Formik initialValues={{ notes: '', patientView: false }} onSubmit={handleSubmit}>
              {({ values, handleChange, handleBlur }) => (
                <Form>
                  <Box sx={{ marginTop: '10px', padding: '20px' }}>
                    <Grid container direction="column" spacing={2}>
                      <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                        Notes For Doctor
                      </Typography>
                      <Grid item>
                        <Typography variant="h5" fontWeight="bold">
                          Notes
                        </Typography>
                        <Textarea
                          variant="outlined"
                          fullWidth
                          rows={4}
                          minRows={4}
                          maxRows={4}
                          name="notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item>
                        <ViewBox>
                          <Typography variant="h5" sx={{ position: 'relative', top: '5px', left: '2%' }}>
                            Patient Can View
                          </Typography>
                          <SwitchComponent
                            name="patientView"
                            sx={{ position: 'relative', left: '70%', bottom: '40px' }}
                            checked={values.patientView}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </ViewBox>
                      </Grid>
                      <Grid container justifyContent="center" sx={{ marginTop: '30px' }}>
                        <Tooltip title="Save the notes" arrow>
                          <Button type="submit" variant="contained" disabled={isLoading}>
                            Save Notes
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </FormBox>
      <FormBox sx={{ marginTop: '40px' }}>
  <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
    <Grid item>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Latest Notes History</Typography>
      {medicalRecords.length > 0 ? (
        medicalRecords.map((note, index) => (
          <Box
      key={index}
      sx={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc' }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {`Date: ${note?.date || 'N/A'}`}
      </Typography>
      <Typography variant="body1">
        {`Notes: ${note?.notes || 'N/A'}`}
      </Typography>
    </Box>
        ))
      ) : (
        <Typography variant="body1">No notes available.</Typography>
      )}
    </Grid>
  </Grid>
</FormBox>
    </>
  );
}

export default Notes;
