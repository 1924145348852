import React from "react";
import { Container } from "@mui/material";
import Profile from "../../components/SettingPage/Profile";
function ProfileContainer() {
    return (
        <>
            
            <Container maxWidth='lg'>
            <Profile/>
            </Container>
        </>
    )
}
export default ProfileContainer