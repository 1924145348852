import React from "react";
import { Container } from "@mui/material";
import Clinic from "../../components/DashBoard/Clinic";
 function ClinicContainer(){
    return(
        <>
             <Container maxWidth='lg'>
                <Clinic />
            </Container>
        </>
    )
 }
 export default ClinicContainer;