import React, { useState } from 'react';
import Clinics from '../../../mock/Clinics'; // Adjust the path as necessary
import { Typography, Grid, Box, Divider, Button } from '@mui/material';
import { palette } from 'src/config/theme';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ClinicsContainer() {
  const [expandedClinics, setExpandedClinics] = useState(null); // State to store expanded clinic index or null if none expanded

  const handleToggleDetails = (index) => {
    if (expandedClinics === index) {
      setExpandedClinics(null); // Collapse if already expanded
    } else {
      setExpandedClinics(index); // Expand if not expanded
    }
  };
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  const handleViewDetails = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/clinic`);
  };

  return (
    <>
      {Clinics.map((clinic, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: palette.white,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            width: '80%',
            borderRadius: '16px',
            position: 'relative',
            bottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            marginBottom: '20px',
            transition: 'height 0.3s ease',
            height: expandedClinics === index ? 'auto' : 'fit-content', // Adjust height based on expansion state
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h3"
                color="#393A96"
                fontWeight="bold"
                sx={{ marginBottom: '20px' }}
              >
                {clinic.clinicName}
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                Address
              </Typography>
              <Typography variant="body1">{clinic.address}</Typography>
              <Typography variant="h5" fontWeight="bold" sx={{ marginTop: '10px' }}>
                Contact Number
              </Typography>
              <Typography variant="body1">{clinic.contactNumber}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewDetails(index)}
                sx={{ marginTop: '20px' }}
              >
                {expandedClinics === index ? 'Hide Details' : 'View Details'}
              </Button>
            </Box>
          </Box>
          {expandedClinics === index && (
            <Box sx={{ marginTop: '20px' }}>
              <Divider sx={{ marginBottom: '20px' }} />
              {/* Additional details to display when expanded */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" color="primary">
                    View Details
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
}

export default ClinicsContainer;
