import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { palette, } from "src/config/theme";
import { Typography } from "@mui/material";

export const FormBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px',
    position:'relative',
    right:'2.5%'
    
})

export const TotalBox = styled(Box)({

    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '27%',
    borderRadius: '16px',
    position: 'relative',
  top:'10px'

})

export const AreaBox = styled(Box)({

    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '27%',
    borderRadius: '16px',
    position: 'relative',
  top:'10px',
    right: "9%"

})


export const InsightBox = styled(Box)({

    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '27%',
    borderRadius: '16px',
    position: 'relative',
bottom:'-10px',
    right: "18%"

})

export const ProfileBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '82%',
    borderRadius: '16px',
    position: 'relative',
    bottom: '30px'
})
export const NameTypography = styled(Typography)({
    position: 'relative', top: -18, left: '50%'
})
export const TaskBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '95%',
    borderRadius: '16px',
    position: 'relative',
    left:'10px',
    bottom: '30px'

})

export const PaymentBox = styled(Box)({

    border: '1px solid #D3D3D3',
    height: '15vh',
    width: '92%',
    borderRadius: 5,
    position: 'relative',
    top: '5px',
    left: '4%'


})

export const ProfileBackBox = styled(Box)({
    background: '#393A96',
    padding: '10px',
    borderRadius: '10px',
    width: '700px',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
    right: '10px',
    bottom: '200px'
})