import React from "react";
import { Container } from "@mui/material";
import PrescriptionTemplate from "../../components/SettingPage/PrescriptionTemplate";
 function PrescriptionTemplateContainer(){
    return(
        <>
        
        <Container maxWidth="lg">
        <PrescriptionTemplate/>

      </Container>
        </>
    )
 }
 export default PrescriptionTemplateContainer;