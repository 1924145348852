import DashboardContainer from '../container/OpdContainer';
import AppointmentContainer from '../container/AppointmentContainer';
import PatientsContainer from '../container/PatientsContainer';
import TasksContainer from '../container/TasksContainer';
import PatientAppointmentContainer from '../container/RightSidebarContainer/PatientAppointmentContainer';
import PatientTokenContainer from '../container/RightSidebarContainer/PatientTokenContainer';
import CaseContainer from '../container/RightSidebarContainer/CaseContainer';
import TaskContainer from '../container/RightSidebarContainer/TaskContainer';
import PrescriptionContainer from '../container/RightSidebarContainer/PrescriptionContainer';
import NotesContainer from '../container/RightSidebarContainer/NotesContainer';
import AreaContainer from '../container/RightSidebarContainer/AreaContainer';
import MessageContainer from '../container/RightSidebarContainer/MessageContainer';
import StaffContainer from '../container/RightSidebarContainer/StaffContainer';
import FollowupContainer from '../container/RightSidebarContainer/FollowupContainer';
import TreatmentContainer from '../container/RightSidebarContainer/TreatmentContainer';
import BillingContainer from '../container/BillingContainer';
import PerformanceDashboardCotainer from '../container/DashboardContainer';
import SettingContainer from '../container/RightSidebarContainer/SettingContainer';
import ClinicContainer from '../container/RightSidebarContainer/ClinicContainer';
import BillContainer from '../container/RightSidebarContainer/BillContainer';
import ContactusContainer from '../container/SettingpageContainer/ContactusContainer';
import RateusContainer from '../container/SettingpageContainer/RateusContainer';
import EditprofileContainer from '../container/SettingpageContainer/EditprofileContainer';
import ChangepasswordContainer from '../container/SettingpageContainer/ChangepasswordContainer';
import LanguageContainer from '../container/SettingpageContainer/LanguageContainer';
import PrescriptionTemplateContainer from '../container/SettingpageContainer/PrescriptionTemplateContainer';
import SharePrescriptionContainer from '../container/RightSidebarContainer/SharePrescriptionContainer';
import ClinicsContainer from '../container/ClinicsContainer';
import StaffsContainer from '../container/StaffsContainer';
import PatientContainer from '../container/RightSidebarContainer/PatientContainer';
import ProfileContainer from '../container/SettingpageContainer/ProfileContainer';
import TagContainer from '../container/RightSidebarContainer/TagContainer';
import NotificationsContainer from '../container/SettingpageContainer/NotificationsContainer';
export default [
  {
    title: 'dashboard',
    component: DashboardContainer,
    url: '/:clinicId/:doctorId/',
    exact: true,
    auth: false,
  },
  {
    title: 'appointment',
    component: AppointmentContainer,
    url: '/:clinicId/:doctorId/appointment',
    exact: true,
    auth: false,
    permission: 'appointment',
  },
  {
    title: 'patients',
    component: PatientsContainer,
    url: '/:clinicId/:doctorId/patients',
    exact: true,
    auth: false,
    permission: 'patients',
  },
  {
    title: 'patientAppointment',
    component: PatientAppointmentContainer,
    url: '/:clinicId/:doctorId/patientappointment/:patientId',
    exact: true,
    auth: false,
    permission: 'appointment',
  },
  {
    title: 'patientAppointment',
    component: PatientAppointmentContainer,
    url: '/:clinicId/:doctorId/patientappointment',
    exact: true,
    auth: false,
    permission: 'appointment',
  },
  {
    title: 'patientToken',
    component: PatientTokenContainer,
    url: '/:clinicId/:doctorId/patienttoken/:patientId',
    exact: true,
    auth: false,
    permission: 'token',
  },
  {
    title: 'patientCase',
    component: CaseContainer,
    url: '/:clinicId/:doctorId/case/:patientId',
    exact: true,
    auth: false,
    permission: 'case',
  },
  {
    title: 'Task',
    component: TaskContainer,
    url: '/:clinicId/:doctorId/task',
    exact: true,
    auth: false,
    permission: 'task',
  },
  {
    title: 'Prescription',
    component: PrescriptionContainer,
    url: '/:clinicId/:doctorId/prescripation/:patientId',
    exact: true,
    auth: false,
    permission: 'prescriptions',
  },
  {
    title: 'Notes',
    component: NotesContainer,
    url: '/:clinicId/:doctorId/notes/:patientId',
    exact: true,
    auth: false,
    permission: 'notes',
  },
  {
    title: 'Area',
    component: AreaContainer,
    url: '/:clinicId/:doctorId/area/:patientId',
    exact: true,
    auth: false,
    permission: 'area',
  },
  {
    title: 'Message',
    component: MessageContainer,
    url: '/:clinicId/:doctorId/message/:patientId',
    exact: true,
    auth: false,
    permission: 'messages',
  },
  {
    title: 'Staff',
    component: StaffContainer,
    url: '/:clinicId/:doctorId/staff/:patientId',
    exact: true,
    auth: false,
    permission: 'staff',
  },
  {
    title: 'Staff',
    component: StaffContainer,
    url: '/:clinicId/:doctorId/staff',
    exact: true,
    auth: false,
    permission: 'staff',
  },
  {
    title: 'Followup',
    component: FollowupContainer,
    url: '/:clinicId/:doctorId/followup/:patientId',
    exact: true,
    auth: false,
    permission: 'followUp',
  },
  {
    title: 'Treatment',
    component: TreatmentContainer,
    url: '/:clinicId/:doctorId/treatment/:patientId',
    exact: true,
    auth: false,
    permission: 'treatment',
  },

  {
    title: 'Billing & payments',
    component: BillingContainer,
    url: '/:clinicId/:doctorId/billing',
    exact: true,
    auth: false,
    permission: 'billing',
  },
  {
    title: 'Performance',
    component: PerformanceDashboardCotainer,
    url: '/:clinicId/:doctorId/performance',
    exact: true,
    auth: false,
  },
  {
    title: 'Clinic',
    component: ClinicContainer,
    url: '/:clinicId/:doctorId/clinic',
    exact: true,
    auth: false,
    permission: 'clinic',
  },
  {
    title: 'Settings',
    component: SettingContainer,
    url: '/:clinicId/:doctorId/Settings',
    exact: true,
    auth: false,
  },
  {
    title: 'Tasks',
    component: TasksContainer,
    url: '/:clinicId/:doctorId/tasks',
    exact: true,
    auth: false,
    permission: 'task',
  },
  {
    title: 'Bill',
    component: BillContainer,
    url: '/:clinicId/:doctorId/bill/:patientId',
    exact: true,
    auth: false,
    permission: 'billing',
  },
  {
    title: 'Contactus',
    component: ContactusContainer,
    url: '/:clinicId/:doctorId/Contactus',
    exact: true,
    auth: false,
  },
  {
    title: 'Rateus',
    component: RateusContainer,
    url: '/:clinicId/:doctorId/Rateus',
    exact: true,
    auth: false,
  },
  {
    title: 'Editprofile',
    component: EditprofileContainer,
    url: '/:clinicId/:doctorId/Editprofile',
    exact: true,
    auth: false,
  },
  {
    title: 'Changepassword',
    component: ChangepasswordContainer,
    url: '/:clinicId/:doctorId/Changepassword',
    exact: true,
    auth: false,
  },
  {
    title: 'Language',
    component: LanguageContainer,
    url: '/:clinicId/:doctorId/Language',
    exact: true,
    auth: false,
  },
  {
    title: 'Notifications',
    component: NotificationsContainer,
    url: '/:clinicId/:doctorId/Notifications',
    exact: true,
    auth: false,
  },
  {
    title: 'PrescriptionTemplate',
    component: PrescriptionTemplateContainer,
    url: '/:clinicId/:doctorId/PrescriptionTemplate',
    exact: true,
    auth: false,
    permission: 'prescriptions',
  },
  {
    title: 'SharePrescription',
    component: SharePrescriptionContainer,
    url: '/:clinicId/:doctorId/SharePrescripation/:patientId',
    exact: true,
    auth: false,
    permission: 'prescriptions',
  },
  {
    title: 'Clinics',
    component: ClinicsContainer,
    url: '/:clinicId/:doctorId/clinics',
    exact: true,
    auth: false,
    permission: 'clinic',
  },
  {
    title: 'Staffs',
    component: StaffsContainer,
    url: '/:clinicId/:doctorId/staffs',
    exact: true,
    auth: false,
    permission: 'staff',
  },
  {
    title: 'Patient',
    component: PatientContainer,
    url: '/:clinicId/:doctorId/patient/:patientId',
    exact: true,
    auth: false,
    permission: 'patients',
  },
  {
    title: 'Patient',
    component: PatientContainer,
    url: '/:clinicId/:doctorId/patient',
    exact: true,
    auth: false,
    permission: 'patients',
  },
  {
    title: 'Profile',
    component: ProfileContainer,
    url: '/:clinicId/:doctorId/Profile',
    exact: true,
    auth: false,
  },
  {
    title: 'Tag',
    component: TagContainer,
    url: '/:clinicId/:doctorId/tag/:patientId',
    exact: true,
    auth: false,
    permission: 'tag',
  },
];
