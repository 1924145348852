import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import Treatment from "../../components/RightSidebar/Treatment";
import { Container } from "@mui/material";
function TaskContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information/>
            
            <Container maxWidth='lg'>
           
                <Treatment />
            </Container>
        </>
    )
}
export default TaskContainer