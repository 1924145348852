import React from "react";
import Editprofile from "../../components/SettingPage/Editproflie";
import { Container } from "@mui/material";
function EditprofileContainer() {
  return (
    <>
      <Container maxWidth="lg">
        <Editprofile />
      </Container>
    </>
  )
}
export default EditprofileContainer;