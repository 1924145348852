import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { palette, } from "src/config/theme";
import { Typography } from "@mui/material";

export const FormBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '82%',
    borderRadius: '16px',
    position: 'relative',
    bottom: '35px'

})
export const ClinicBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px',
    position: 'relative',
    bottom:'40px',right:'2.5%'
    
})
export const InfoBox = styled(Box)({
    backgroundColor: palette.primary.main,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '45%',
    height: '50vh',

    borderRadius: '16px',
    position: 'relative',
    left: '50%',
    top: '50px'

})

export const DesignBox = styled(Box)({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white', 
    borderRadius: '50%',
    padding: '8px',
})
export const ContactBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '105%',
    height: '10vh',

    borderRadius: '16px',
    position: 'relative',
    top:'20px'
    

})
export const RateBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '50%',
    height: '45vh',

    borderRadius: '16px',
    position: 'relative',
    top:'40px',left:'25%'
    

})
export const PaymentBox = styled(Box)({

    border: '1px solid #D3D3D3',
    height: '15vh',
    width: '92%',
    borderRadius: 5,
    position: 'relative',
    top: '5px',
    left:'4%'


})

export const ProfileBackBox =styled(Box)({
    background: '#393A96',
    padding: '10px',
    borderRadius: '10px',
    width: '750px',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
    right: '10px',
    bottom: '10px'
})
export const ProfileBox =styled(Box)({
backgroundColor: palette.white,
boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
width: '82%',
borderRadius: '16px',
position: 'relative',
bottom:'40px'
})

