import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import Area from "../../components/RightSidebar/Area";
import { Container } from "@mui/material";
function AreaContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information />
            <Container maxWidth='lg'>
                <Area />
            </Container>

        </>
    )
}
export default AreaContainer;