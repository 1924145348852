import { ReactComponent as Dashboard } from 'src/assets/images/sidebar/dashboard.svg';
import { ReactComponent as Appointment } from 'src/assets/images/sidebar/appointment.svg';
import { ReactComponent as Patients } from 'src/assets/images/sidebar/patients.svg';
import { ReactComponent as Billing } from 'src/assets/images/sidebar/billing.svg';
import { ReactComponent as Clinics } from 'src/assets/images/sidebar/clinics.svg';
import { ReactComponent as Settings } from 'src/assets/images/sidebar/settings.svg';
import { ReactComponent as Staff } from 'src/assets/images/sidebar/staff.svg';
import { ReactComponent as Tasks } from 'src/assets/images/sidebar/tasks.svg';
import { ReactComponent as Opd } from 'src/assets/images/sidebar/opd1.svg'

const iconConfig = {
  opd: Opd,
  dashboard: Dashboard,
  appointment: Appointment,
  patients: Patients, 
  billing: Billing,
  clinics: Clinics,
  settings: Settings,
  tasks: Tasks,
  staff: Staff,
};

export default iconConfig;
