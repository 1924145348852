import React from "react";
import { Container } from "@mui/material";
import Changepassword from "../../components/SettingPage/Changepassword";
function ChangepasswordContainer() {
  return (
    <>
      <Container maxWidth="lg">
        <Changepassword/>
      </Container>
    </>
  )
}
export default ChangepasswordContainer;