import React from "react";
import { Container } from "@mui/material";
import Notifications from "../../components/SettingPage/Notifications";
function NotificationContainer() {
  return (
    <>
      <Container maxWidth="lg">
        <Notifications/>
      </Container>
    </>
  )
}
export default NotificationContainer;