import React from "react";
import Welcome from "../../components/patient/Welcome";
import Staff from "../../components/RightSidebar/Staff";
import { Container } from "@mui/material";
function StaffContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Container maxWidth='lg'>
                <Staff />
            </Container>
        </>
    )
}
export default StaffContainer