import React, { useState } from 'react';
import { IconButton, Box } from '@mui/material';
import Sidebar from './RightSidebar'; // Ensure this path is correct
import { styled } from '@mui/material/styles';

const BackgroundSquare = styled(Box)(({ open }) => ({
  width: '50px',
  height: '50px',
  backgroundColor: open ? '#ffffff' : '#393A96',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  transition: 'background-color 0.3s ease-in-out',
  

}));

const PlusIcon = styled('div')(({ open }) => ({
  position: 'relative',
  width: '20px',
  height: '20px', 
  '& .bar': {
    position: 'absolute',
    width: '100%',
    height: '3px',
    backgroundColor: open ? '#393A96' : '#bdbaba',
    transition: 'transform 0.3s ease-in-out',
  },
  '& .bar1': {
    top: '50%',
    left: '0',
    transform: open ? 'rotate(45deg)' : 'rotate(0)',
    transformOrigin: 'center',
    marginTop: '-1.5px', // Center the bar vertically
  },
  '& .bar2': {
    top: '50%',
    left: '0',
    transform: open ? 'rotate(-45deg)' : 'rotate(90deg)',
    transformOrigin: 'center',
    marginTop: '-1.5px', // Center the bar vertically
  },
}));

function SidebarToggle() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <BackgroundSquare open={isSidebarOpen} >
        <IconButton onClick={toggleSidebar} >
          <PlusIcon open={isSidebarOpen}>
            <div className="bar bar1" />
            <div className="bar bar2" />
          </PlusIcon>
        </IconButton>
      </BackgroundSquare>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
}

export default SidebarToggle;
