import React, { useState } from "react";
import { FormBox } from "./style";
import { Typography, Divider, Grid } from "@mui/material";
import { Button } from 'src/components/shared';
import InputComponent from "src/components/shared/Form/Input";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);

    // Update password strength
    if (value.length < 6) {
      setPasswordStrength('Weak');
    } else if (value.length < 10) {
      setPasswordStrength('Moderate');
    } else {
      setPasswordStrength('Strong');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Check if passwords match
    if (value !== newPassword) {
      setErrorMessage('Passwords do not match');
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = () => {
    // Handle password change submission
  };

  return (
    <FormBox height="80vh">
      <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center' sx={{ position: 'relative', top: '15px' }}>
        Change Your Password
      </Typography>
      <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
      <Typography variant="h5" fontWeight='bold' textAlign='center' sx={{ position: 'relative', top: '15px', left: "4%" }}>
        Your new password must be different from your previous password
      </Typography>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">Current Password</Typography>
          <InputComponent 
            variant='outlined' 
            fullWidth 
            type="password" 
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">New Password</Typography>
          <InputComponent 
            variant='outlined' 
            fullWidth 
            type="password" 
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
          <div style={{ minHeight: '24px' }}>
            {newPassword && (
              <Typography variant="body2" color={
                passwordStrength === 'Weak' ? 'error' : 
                passwordStrength === 'Moderate' ? 'warning.main' : 'success.main'
              }>
                Password Strength: {passwordStrength}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item sx={{position:'relative',bottom:'20px'}}>
          <Typography variant="h5" fontWeight="bold">Confirm Password</Typography>
          <InputComponent 
            variant='outlined' 
            fullWidth 
            type="password" 
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <div style={{ minHeight: '24px' }}>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid container spacing={2} justifyContent="center" >
          <Button variant='contained' sx={{ textAlign: 'center' }} onClick={handleSubmit}>Change Password</Button>
        </Grid>
      </Grid>
    </FormBox>
  );
}

export default ChangePassword;
