import { styled } from '@mui/system';
import images from 'src/config/images';
import ListItemButton from '@mui/material/ListItemButton';
import theme from 'src/config/theme';

export const Logo = styled('img')({
  maxWidth: '200px',
  margin: '0 auto',
  paddingTop: 30,
  paddingBottom: 30,
});
Logo.defaultProps = {
  src: images.logoW,
};

export const ListItem = styled(ListItemButton)({
  '& .MuiListItemText-root': {
    color: '#ffffff',
    opacity: 0.6,
    '& .MuiTypography-root': {
      fontSize: '18px !important',
      lineHeight: '18px !important',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '44px',
    opacity: 0.6,
  },
  padding: '12px 24px',
  '&.active': {
    backgroundColor: theme.palette.secondary.main,
    '& .MuiListItemText-root': {
      opacity: 1,
      '& .MuiTypography-root': {
        fontWeight: 500,
      },
    },
    '& .MuiListItemIcon-root': {
      opacity: 1,
    },
  },
});

export const Icon1 = styled('img')({
  position: 'absolute',
  bottom: '0px',
  left: '00px',
  width: '280px',
});
Icon1.defaultProps = {
  src: images.auth.icon,
};
