import React, { useState } from "react";
import { Grid, Typography, Divider } from '@mui/material';
import { FormBox } from "./style";
import InputComponent from "src/components/shared/Form/Input";
import Textarea from "src/components/shared/Form/Textarea";
import { Button } from 'src/components/shared';

function PrescriptionTemplate() {
    const [title, setTitle] = useState('');
    const [prescription, setPrescription] = useState('');
    const [savedPrescriptions, setSavedPrescriptions] = useState([]);

    const handleTitleChange = (value) => {
        setTitle(value);
    };

    const handlePrescriptionChange = (e) => {
        setPrescription(e.target.value);
    };

    const handleSave = () => {
        setSavedPrescriptions([...savedPrescriptions, { title, prescription }]);
        setTitle('');
        setPrescription('');
    };

    return (
        <>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <FormBox height="65vh">
                        <Typography variant="h4" color="#393A96" fontWeight="bold" textAlign="center"
                            sx={{ position: 'relative', top: '15px' }} >
                            Prescription
                        </Typography>

                        <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                        <Typography
                            variant="h4"
                            color="#393A96"
                            fontWeight="bold"
                            sx={{
                                position: 'relative',
                                top: 20,
                                left: '4%',
                            }}
                        >
                            Prescription Template
                        </Typography>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Title</Typography>
                                <InputComponent 
                                    value={title}
                                    onChange={handleTitleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Prescription</Typography>
                                <Textarea
                                    value={prescription}
                                    onChange={handlePrescriptionChange}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item container spacing={2} justifyContent="center" sx={{marginTop:'10px'}}>
                                <Button variant='contained' sx={{ textAlign: 'center' }} onClick={handleSave}>Save</Button>
                            </Grid>
                        </Grid>
                    </FormBox>
                </Grid>
            </Grid>

            <Grid container direction="column" spacing={2} marginTop='10px'>
                {savedPrescriptions.map((prescription, index) => (
                    <Grid item key={index}>
                        <FormBox height="25vh">
                            <Typography variant="h4" color="#393A96" fontWeight="bold" textAlign="center"
                                sx={{ position: 'relative', top: '15px' }} >
                                Prescription Template
                            </Typography>

                            <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Title: {prescription.title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Prescription: {prescription.prescription}</Typography>
                                </Grid>
                            </Grid>
                        </FormBox>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default PrescriptionTemplate;
