import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  FormHelperText,
} from '@mui/material';
import { useField } from 'formik';

function SwitchComponent({ label, labelPlacement, color, helperText, sx, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <Box mt={2} sx={sx}>
      <FormControl component="fieldset" error={!!errorText}>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch {...field} checked={field.value} color={color} />}
            label={label}
            labelPlacement={labelPlacement}
            {...props}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormGroup>
      </FormControl>
    </Box>
  );
}

SwitchComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'success', 'error']),
  sx: PropTypes.object,
  helperText: PropTypes.string,
};

SwitchComponent.defaultProps = {
  className: undefined,
  label: undefined,
  labelPlacement: 'end',
  color: 'primary',
  sx: {},
  helperText: '',
};

export default SwitchComponent;
