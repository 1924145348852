import React from 'react';
import { FormBox } from './style';
import { Divider, Grid, Typography, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Setting() {
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);

  const handleClickContactus = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/contactus`);
  };

  const handleClickRateus = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/rateus`);
  };

  const handleClickEditprofile = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/profile`);
  };

  const handleClickChangepassword = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/Changepassword`);
  };

  const handleClickChangeLanguage = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/Language`);
  };

  const handleClickChangeNotifications = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/Notifications`);
  };

  return (
    <>
      <FormBox>
        <Typography
          variant="h3"
          color="#393A96"
          fontWeight="bold"
          textAlign="center"
          sx={{ position: 'relative', top: '15px' }}
        >
          Setting
        </Typography>
        <Divider
          orientation="horizontal"
          sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }}
        />
        <Typography
          variant="h4"
          color="#393A96"
          fontWeight="bold"
          sx={{ position: 'relative', top: '15px', left: '4%' }}
        >
          Account Setting
        </Typography>

        <Grid container direction="column" spacing={2} marginTop="20px" marginLeft="15px">
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickEditprofile}
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Edit Profile
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickChangepassword}
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Change Password
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickChangeLanguage}
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Language
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickChangeNotifications}
              justifyContent="space-between"
              sx={{ width: '90%', marginTop: '15px' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Notification
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography
                variant="h4"
                color="#393A96"
                fontWeight="bold"
                sx={{ position: 'relative', top: '15px' }}
              >
                More Information
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                About Us
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickContactus}
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Contact Us
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleClickRateus}
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Rate Us
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Privacy Policy
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Share Your Feedback
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Terms & Conditions
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
            <Divider orientation="horizontal" sx={{ marginTop: '10px', width: '90%' }} />
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '90%' }}
            >
              <Typography variant="h5" fontWeight="bold">
                Refer Us & Get Benefits
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Stack>
          </Grid>
        </Grid>
      </FormBox>
    </>
  );
}

export default Setting;
