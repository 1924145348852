import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';
import { ListItem } from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function Item({ item, pathname }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  const className = pathname === item.link ? 'active AppMenuItem' : 'AppMenuItem';

  const handleClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}${item.link}`);
  };

  const title = t(item.title);

  return (
    <ListItem className={className} onClick={handleClick}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}

export default Item;
