import React, { useState } from "react";
import { FormBox } from "./style";
import { Divider, Grid, Typography, Snackbar, Alert } from "@mui/material";
import LanguageSelector from 'src/components/language-selector';
function Language() {
  const [notification, setNotification] = useState({
    open: false,
    message: ''
  });
  const handleLanguageChange = (newLanguage) => {
    setNotification({
      open: true,
      message: `Your default language is changed to ${newLanguage}`
    });
  };
  const handleClose = () => {
    setNotification({
      ...notification,
      open: false
    });
  };
  return (
    <>
      <FormBox height="30vh">
        <Typography variant="h4" color="#393A96" fontWeight="bold" textAlign="center"
          sx={{ position: 'relative', top: '15px' }} >
          Select Your Language
        </Typography>
        <Divider orientation="horizontal" sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Grid item>
            <Typography variant="h5" fontWeight="bold">Language</Typography>
            <LanguageSelector onLanguageChange={handleLanguageChange} />
          </Grid>
        </Grid>
      </FormBox>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
}
export default Language;
