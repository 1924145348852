import React from "react";
import Welcome from "../../components/patient/Welcome";
import PatientInfo from "../../components/patient/PatientInfo";
import { Container } from "@mui/material";
import PatientAppointment from "../../components/RightSidebar/PatientAppointment";
import Information from "../../components/patient/Information";
function PatientAppointmentContainer (){
    return(
        <>
            {/* <Welcome/> */}
            <Information/>
           
            <Container maxWidth='lg'>
           
                <PatientAppointment/>
            </Container>
        </>
    )
}
export default PatientAppointmentContainer;